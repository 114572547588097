<script setup>
import GuestLayout from "@/layouts/GuestLayout.vue";
</script>

<template>
	<GuestLayout>
		<router-link to="/login" class="text-white d-block mb-5">
			<font-awesome-icon class="display-6 me-2" :icon="['fas', 'arrow-left']" />
			<span class="h1 fw-normal m-0">{{ $t("back") }}</span>
		</router-link>
		<div class="display-5 text-secondary mb-2">
			{{ $t("reset_password.request_new") }}
		</div>
		<span class="display-1 text-uppercase text-white">{{ $t("reset_password.password") }}</span>
		<!-- TODO:
            url: reset-password?reset_id={id}
            confirm
        -->

		<div v-if="loading">
			<span class="spinner-border spinner-border-lg mt-5" role="status" aria-hidden="true"></span>
		</div>
		<div v-else>
			<div v-if="validToken">
				<Form @submit="newPassword" :validation-schema="schemaNewPassword" class="mt-5 pt-lg-3">
					<label for="new_password" class="form-label text-white"
						><small>{{ $t("reset_password.new_password") }}*</small></label
					>
					<div class="mb-3">
						<div class="login-input position-relative">
							<Field
								:type="showPassword ? 'text' : 'password'"
								class="form-control bg-info text-secondary border-0 border-bottom border-black border-3 rounded-0 py-3"
								id="new_password"
								name="new_password"
							/>
							<div class="position-absolute end-0 top-0 bottom-0 px-2 d-flex align-items-center">
								<div @click="showPassword = !showPassword" class="cursor-pointer">
									<font-awesome-icon
										class="text-secondary cursor-pointer me-2"
										:icon="['fas', showPassword ? 'eye-slash' : 'eye']"
									/>
								</div>
							</div>
						</div>
						<ErrorMessage name="new_password" class="text-danger d-inline-block mt-1" />
					</div>
					<label for="new_password_repeat" class="form-label text-white"
						><small>{{ $t("reset_password.new_password_repeat") }}*</small></label
					>
					<div class="mb-3">
						<div class="login-input position-relative">
							<Field
								:type="showPassword ? 'text' : 'password'"
								class="form-control bg-info text-secondary border-0 border-bottom border-black border-3 rounded-0 py-3"
								id="new_password_repeat"
								name="new_password_repeat"
							/>
							<div class="position-absolute end-0 top-0 bottom-0 px-2 d-flex align-items-center">
								<div @click="showPassword = !showPassword" class="cursor-pointer">
									<font-awesome-icon
										class="text-secondary cursor-pointer me-2"
										:icon="['fas', showPassword ? 'eye-slash' : 'eye']"
									/>
								</div>
							</div>
						</div>
						<ErrorMessage name="new_password_repeat" class="text-danger d-inline-block mt-1" />
					</div>

					
					<div
						id="resetMessage"
						class="text-danger d-inline-block mb-n4"
						:class="resetMessage != '' ? 'd-block' : 'd-none'"
					>
						{{ resetMessage != "" ? resetMessage : "&nbsp;" }}
					</div>

					<!-- <div id="loginError" class="text-danger d-inline-block mt-1" :class="loginError != '' ? 'd-block' : 'd-none'">{{ loginError != '' ? loginError : '&nbsp;'}}</div> -->
					<div class="mt-5 d-flex justify-content-between align-items-center">
						<button v-if="validating" class="btn btn-primary" type="button" disabled>
							<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							{{ $t("reset_password.check") }}
						</button>
						<button v-else class="btn btn-primary" type="submit">
							{{ $t("reset_password.set_new_password") }}
						</button>
					</div>
				</Form>
			</div>
			<div v-else>
				<Form @submit="requestReset" :validation-schema="schemaRequestReset" class="mt-5 pt-lg-3">
					<div class="mb-3">
						<label for="email" class="form-label text-white">{{ $t("reset_password.email") }}</label>
						<div class="login-input position-relative">
							<Field
								name="email"
								type="email"
								class="form-control bg-info text-secondary border-0 border-bottom border-black border-3 rounded-0 py-3"
							/>
						</div>
						<ErrorMessage name="email" class="text-danger d-inline-block mt-1" />
					</div>
					<div
						id="resetMessage"
						class="text-primary d-inline-block mb-n4"
						:class="resetMessage != '' ? 'd-block' : 'd-none'"
					>
						{{ resetMessage != "" ? resetMessage : "&nbsp;" }}
					</div>
					<div class="mt-5 d-flex justify-content-between align-items-center">
						<button v-if="validating" class="btn btn-primary" type="button" disabled>
							<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							{{ $t("reset_password.check") }}
						</button>
						<button v-else @click="resetMessage = ''" class="btn btn-primary" type="submit">
							{{ $t("reset_password.send_reset_mail") }}
						</button>
					</div>
				</Form>
			</div>
		</div>
	</GuestLayout>
</template>

<script>
// import Toast from '@/js/toast.js';
import { useUserStore } from "@/js/user.js";
import { ref } from "vue";
import { Form, Field, ErrorMessage, configure } from "vee-validate";
import * as yup from "yup";
import { i18n } from "@/main";

configure({
	validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
	validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
	validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
	validateOnModelUpdate: false // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

const schemaRequestReset = yup.object({
	email: yup
		.string()
		.required(() => i18n.global.t("reset_password.validations.email_required"))
		.email(() => i18n.global.t("reset_password.validations.use_valid_email"))
});

const schemaNewPassword = yup.object({
	new_password: yup
		.string()
		.required(() => i18n.global.t("reset_password.validations.enter_new_password"))
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,}$/,
			() => i18n.global.t("reset_password.validations.password_invalid")
		),
	new_password_repeat: yup
		.string()
		.required(() => i18n.global.t("reset_password.validations.repeat_password"))
		.oneOf([yup.ref("new_password")], () => i18n.global.t("reset_password.validations.password_must_match"))
});

export default {
	data() {
		return {
			loading: true,
			validToken: false,
			email: "",
			password: "",
			validating: ref(false),
			resetMessage: "",
			showPassword: ref(false),
			resetId: ""
		};
	},
	methods: {
		async requestReset(values) {
			if (values.email == "") return false;
			this.validating = true;
			const userStore = useUserStore();
			let state = await userStore.requestReset(values.email);
			this.resetMessage = this.$t("reset_password.request_notice");
			this.validating = false;
		},
		async newPassword(values) {
			const userStore = useUserStore();
			let state = await userStore.newPassword(this.resetId, values.new_password);

			if (state == "error" || state !== 204) {
				this.resetMessage = this.$t("reset_password.validations.unexpected_failure");
			}
		}
	},
	async beforeMount() {
		const userStore = useUserStore();
		this.resetId = this.$router.currentRoute.value.query.reset_id;

		if (this.resetId != undefined) {
			let state = await userStore.validateToken(this.resetId);
			if (state == 204) this.validToken = true;
			else this.resetMessage = this.$t("reset_password.validations.link_expired");
		}

		this.loading = false;
	}
};
</script>

<style lang="scss">
.login-input {
	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 3px;
		background-color: $primary;
		width: 0;
		transition: 0.4s ease;
	}

	&:focus-within:after {
		width: 100%;
	}

	.form-control {
		outline: 0 !important;
		box-shadow: none !important;
	}
}
</style>
