<script setup>
import ApplicationLogo from "@/components/ApplicationLogo.vue";
import LanguageSelector from "@/components/LanguageSelector.vue";
</script>

<template>
	<div class="py-3 bg-info">
		<div class="container d-flex justify-content-between">
			<div>
				{{ $t("contact_support") }}:
				<a :href="`mailto:${helpEmailAddress}`">{{ helpEmailAddress }}</a>
			</div>
			<LanguageSelector></LanguageSelector>
		</div>
	</div>
	<div class="container">
		<div class="row align-items-center gy-5">
			<div class="col-12 col-sm-12 col-lg-auto order-0 text-center">
				<ApplicationLogo fillColor="#fff" />
			</div>
			<div class="col-12 col-lg-6 ps-lg-5 order-3 order-lg-1">
				<slot name="title"></slot>
			</div>
			<div id="nav-links" class="ms-sm-auto ms-0 order-2 col-sm-auto col-12 text-center">
				<MultiCustomers v-if="user.customers.length > 0" />
				<router-link to="/profile" class="text-body me-4 link-opacity-50-hover"
					>{{ $t("navigation.profile") }} <font-awesome-icon class="ms-2" :icon="['fas', 'user']"
				/></router-link>
				<a
					class="text-body me-4 link-opacity-50-hover"
					href="#"
					data-bs-toggle="modal"
					data-bs-target="#affiliateModal"
					>{{ $t("affiliate.affiliate_popup.title") }}
					<img :src="require('/src/assets/affiliate.png')" class="affiliate-icon" alt="Affiliate icon" />
				</a>
				<a class="text-body link-opacity-50-hover" href="#" @click="logout"
					>{{ $t("navigation.logout") }}
					<font-awesome-icon class="ms-2" :icon="['fas', 'right-from-bracket']"
				/></a>
			</div>
		</div>
	</div>

	<div v-if="user.customers.length === 0" class="container mt-5 text-center text-danger display-6">
		{{ $t("account_not_setup_yet") }}
	</div>

	<AffiliateDialog :userCode="user.user_code" :affiliatePlans="user.affiliate_plans"></AffiliateDialog>
	<VideosDialog :userFeedbacks="userFeedbacks"></VideosDialog>
	<FeedbackDialog :showModal="showLoginFeedback" :feedbackType="feedbackTypes.login"></FeedbackDialog>
	<FeedbackDialog :showModal="showWalletAddressFeedback" :feedbackType="feedbackTypes.address"></FeedbackDialog>

	<slot />

	<!-- Modal -->
	<div
		class="modal fade"
		id="supportModal"
		tabindex="-1"
		data-bs-backdrop="true"
		aria-labelledby="supportModalLabel"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content">
				<button
					type="button"
					class="btn-close btn-close-white"
					data-bs-dismiss="modal"
					aria-label="Close"
				></button>
				<div class="modal-body bg-dark text-white">
					<div class="display-5">{{ $t("support_modal.title") }}</div>
					<h1>{{ $t("support_modal.heading") }}</h1>
					<p>
						{{ $t("support_modal.description") }}
					</p>
					<Form @submit="supportMessage">
						<fieldset>
							<label for="supportSubject" class="form-label"
								><small>{{ $t("support_modal.select_category") }}</small></label
							>
							<div class="mb-3">
								<Field
									as="select"
									id="supportSubject"
									name="supportSubject"
									class="form-select form-select-lg bg-dark text-white"
								>
									<option value="Payment">{{ $t("support_modal.payment") }}</option>
									<option value="Technical">{{ $t("support_modal.technical") }}</option>
									<option value="General">{{ $t("support_modal.general") }}</option>
								</Field>
							</div>
							<div class="mb-3">
								<label for="supportComment" class="form-label"
									><small>{{ $t("support_modal.describe_request") }}</small></label
								>
								<Field
									as="textarea"
									type="text"
									id="supportComment"
									name="supportComment"
									rows="5"
									class="form-control bg-dark text-white"
								/>
							</div>
							<button class="btn btn-primary mt-4" type="submit">{{ $t("support_modal.send") }}</button>
						</fieldset>
						<div
							class="state position-absolute start-0 top-0 end-0 bottom-0 d-flex justify-content-center align-items-center bg-dark"
						>
							<div class="loading text-center position-absolute">
								<div class="icon display-1 mb-3">
									<font-awesome-icon
										class="bg-dark"
										:icon="['fas', 'circle-notch']"
										size="2xl"
										spin
										spin-reverse
									/>
								</div>
							</div>
							<div class="success-message text-center position-absolute">
								<div class="icon display-1 mb-3">
									<font-awesome-icon
										class="text-success bg-dark"
										:icon="['fas', 'circle-check']"
										size="2xl"
									/>
								</div>
								<div class="message">{{ $t("support_modal.request_sent") }}</div>
							</div>
							<div class="failure-message text-center position-absolute">
								<div class="icon display-1 mb-3">
									<font-awesome-icon
										class="text-danger bg-dark"
										:icon="['fas', 'circle-xmark']"
										size="2xl"
									/>
								</div>
								<div class="message">
									{{ $t("support_modal.request_error") }}
								</div>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useUserStore } from "@/js/user";
import { feedbackTypes } from "@/js/constants";
import { Form, Field, ErrorMessage, configure } from "vee-validate";
import * as yup from "yup";
import { resetDialogState } from "@/js/common.js";

import AffiliateDialog from "@/components/dialogs/AffiliateDialog.vue";
import MultiCustomers from "@/components/MultiCustomers.vue";
import VideosDialog from "@/components/dialogs/VideosDialog.vue";
import FeedbackDialog from "@/components/dialogs/FeedbackDialog.vue";

configure({
	validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
	validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
	validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
	validateOnModelUpdate: false // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

export default {
	data() {
		return {
			user: null,
			userFeedbacks: null,
			loginFeedbackType: "login",
			walletAddressFeedbackType: "wallet_address_updated",
			showLoginFeedback: false,
			showWalletAddressFeedback: false,
			isFeedbacksLoaded: false,
			feedbackTypes,
			helpEmailAddress: "helpdesk@block-b.de"
		};
	},
	beforeMount() {
		const user = useUserStore();
		this.user = user.$state.user;
		this.getUserFeedbacks();
	},
	methods: {
		logout(event) {
			const userStore = useUserStore();
			userStore.logout();
			event.preventDefault();
		},
		async supportMessage(values, eventHandler) {
			let userStore = useUserStore();
			eventHandler.evt.target.classList.add("submitted");

			let supportData = {
				body: values["supportComment"],
				subject: "Request regarding " + values["supportSubject"]
			};

			userStore
				.sendFormSupport(supportData)
				.then(response => {
					if (response == 204) {
						eventHandler.evt.target.classList.add("success");
					} else eventHandler.evt.target.classList.add("failure");

					setTimeout(() => {
						eventHandler.evt.target.classList.remove("submitted");
						eventHandler.evt.target.classList.remove("success");
						eventHandler.evt.target.classList.remove("failure");
					}, 4000);
				})
				.catch(error => {
					console.log(error);
				});
		},
		getUserFeedbacks() {
			useUserStore()
				.getFeedbacksByUserID()
				.then(feedbacks => {
					this.isFeedbacksLoaded = true;
					this.userFeedbacks = feedbacks;

					this.showLoginFeedbackIfNeeded(feedbacks);
					this.showWalletAddressIfNeeded(feedbacks);
				});
		},
		showLoginFeedbackIfNeeded(userFeedbacks) {
			if (this.user.nth_login >= 3 && Array.isArray(userFeedbacks)) {
				if (!userFeedbacks.find(val => val.feedback_type === this.loginFeedbackType)) {
					this.showLoginFeedback = true;
				}
			}
		},
		showWalletAddressIfNeeded(userFeedbacks) {
			if (this.user.selected_customer.wallet_address && Array.isArray(userFeedbacks)) {
				if (!userFeedbacks.find(val => val.feedback_type === this.walletAddressFeedbackType)) {
					this.showWalletAddressFeedback = true;
				}
			}
		}
	}
};
</script>
