export const messages = {
    "contact_support": "Kontaktiere den Support",
    "current_language": "Deutsch",
    "confirm": "Bestätigen",
    "back": "Zurück",
    "account_not_setup_yet": "Dein Account ist noch nicht vollständig eingerichtet, bitte komme später wieder.",
    "details": "Einzelheiten",

    "meta_data": {
        "profile": {
            "title": "Profil"
        },
        "login": {
            "title": "Anmelden"
        },
        "dashboard": {
            "title": "Dashboard"
        },
        "reset_password": {
            "title": "Passwort zurücksetzen"
        }
    },

    "login": {
        "email": "E-Mail",
        "password": "Passwort",
        "login": "Anmelden",
        "reset_password": "Passwort zurücksetzen",
        "check": "Prüfen..",
        "dashboard": "Dashboard"
    },

    "new_rewards_popup": {
        "title": "Neue Belohnungen erhalten",
        "body_start": "Seit deinem letzten Login hast du beeindruckende",
        "body_end": " gesammelt!"
    },

    "cancel_popup": {
        "subline": "Du möchtest uns verlassen?",
        "headline": "Stornierung deines Pakets",
        "bodytext": "Im Folgenden kannst du uns mitteilen, warum du deinen Cloud-Sparplan kündigen möchtest. Dein Feedback hilft uns, in Zukunft noch besser auf deine Bedürfnisse einzugehen, danke! Und falls du wirklich durchziehst - pass auf dich auf! Wir freuen uns, dich bald wieder als Kunden begrüßen zu dürfen.",
        "textarea_reason": "Was hat dich zum Rücktritt bewogen?"
    },

    "add_plan_popup": {
        "subline": "Sichere dir mehr Bitcoins mit unseren Paketen",
        "headline": "Wähle dein Paket",
        "bodytext": "Erhalte mehr Cloud-Sicherheitsleistung mit neuen Paketen. Wähle einfach die Anzahl der Pakete aus, die du haben möchtest und sende sie ab - ein Kollege wird sich dann umgehend mit dir in Verbindung setzen, um deine Anfrage umzusetzen!",
        "select_amount": "Wähle die Anzahl der neuen Pakete",
        "textarea_additional": "Möchtest du uns noch etwas dazu mitteilen?",
        "year_with_vat": "Jahr inkl. MwSt.",
        "month_with_vat": "Monat inkl. MwSt.",
        "day_with_vat": "Tag inkl. MwSt.",
        "choose_package": "Wähle dein neues Paket",
        "enter_quantity": "Eingabe der Anzahl",
    },

    "info_savings_popup": {
        "headline": "Bisher generiert",
        "bodytext_1": "Wichtig: Alle hier angezeigten Werte sind Schätzungen und in keiner Weise garantiert. Sie dienen nur zu Veranschaulichungszwecken und sind nicht garantiert.",
        "bodytext_2": "Hier siehst du auf einen Blick, wie viele Bitcoins oder Bitcoin-Bruchteile (sogenannte 'Satoshis') du bereits erfolgreich mit deiner Rechenlinie generiert hast.",
        "bodytext_3": "Der zweite Euro-Betrag bezieht sich auf das bevorstehende 'Halving', d. h. die Halbierung der Belohnung, die Bitcoin-Miner erhalten. Dies hat direkte Auswirkungen auf die Generierung von Bitcoins und somit auf die Anzahl der Satoshis, die du für deine Rechenlinie erhältst - nämlich nur etwa 50% so viel wie vor dem Halving-Datum. Warum ist der Euro-Betrag unter 'Post-Halving-Potenzial ca.' dann deutlich höher als der Wert der aktuellen Satoshis? Achte gut darauf! Du kannst davon ausgehen, dass sich der Bitcoin-Preis nach dem Halving positiv und nach oben entwickeln wird und dass der Preis deiner Satoshis daher auf dem Markt steigen wird. Warum? Einfach gesagt: Aufgrund der Knappheit des Zustroms neuer Bitcoins auf den Markt - nämlich nur noch halb so viele - bei mindestens gleichbleibender (oder sogar steigender) Nachfrage, ist es sehr wahrscheinlich, dass der Preis eines Bitcoins steigen wird. Aber Vorsicht: dies ist keine Garantie und Bitcoin ist und bleibt eine risikoreiche, volatile Anlage. Es besteht das Risiko, dass sich der Preis nicht wie erwartet entwickelt."
    },

    "info_difficulty_popup": {
        "headline": "Rentabilitätsradar",
        "bodytext_1": "Wichtig: Alle hier angezeigten Werte dienen nur zur groben Orientierung und sind nicht garantiert.",
        "bodytext_2": "Die Generierung von Bitcoins - d. h. wie viele Bitcoins oder Bitcoin-Bruchteile (sogenannte 'Satoshis') du als Belohnung für deine Rechenleistung erhältst - wird maßgeblich durch die Netzwerkschwierigkeit, die sogenannte 'Difficulty', beeinflusst. Der Wert wird vom Bitcoin-Netzwerk selbst reguliert und kann weder von dir noch von uns beeinflusst werden. Natürlich gibt es andere Faktoren, aber dies ist einer der wichtigsten. Kurz gesagt: Je höher die Difficulty steigt, desto weniger Satoshis erhältst du. Der aktuelle Schwierigkeitsgrad wird als roter Graph dargestellt. Du findest auch einen grünen Graphen. Dieser zeigt die aktuelle Rentabilität - d. h. wie viele Bitcoins derzeit als Belohnung für ein Giga oder Terahash Rechenleistung zur Verfügung stehen, grob gesagt. Zusammen können die Werte dir eine grobe Vorstellung von der aktuellen Rentabilität geben. Aber bitte beachte: Die Werte dienen nur als grobe Orientierung und sind nicht garantiert."
    },

    "info_computing_popup": {
        "headline": "Deine Rechenleistung",
        "bodytext": "Hier siehst du, wie viel Rechenleistung du derzeit dem Bitcoin-Netzwerk zur Verfügung stellst. Alle deine Pakete werden berücksichtigt und entsprechend zusammengezählt. Um deine Rechenleistung zu erhöhen, kaufe ein weiteres Paket."
    },

    "reset_password": {
        "request_new": "Fordere ein neues",
        "password": "Passwort",
        "email": "E-Mail",
        "send_reset_mail": "Passwort-Reset-Mail senden",
        "new_password": "Neues Passwort",
        "new_password_repeat": "Neues Passwort wiederholen",
        "set_new_password": "Neues Passwort setzen",
        "request_notice": "Wenn ein Benutzer mit dieser E-Mail-Adresse existiert, wird eine E-Mail mit weiteren Anweisungen gesendet.",
        "new_password_set": "Ihr neues Passwort wurde festgelegt!",
        "check": "Prüfen..",
        "validations": {
            "email_required": "E-Mail ist ein Pflichtfeld.",
            "use_valid_email": "Bitte verwenden Sie eine gültige E-Mail.",
            "enter_new_password": "Bitte geben Sie das neue Passwort ein.",
            "password_invalid": "Muss mindestens 8 Zeichen enthalten, davon 1 Großbuchstabe, 1 Sonderzeichen und 1 Zahl.",
            "repeat_password": "Bitte wiederholen Sie das Passwort.",
            "password_must_match": "Passwörter müssen übereinstimmen.",
            "link_expired": "Der Link zum Zurücksetzen des Passworts ist abgelaufen. Bitte fordern Sie ein neues an.",
            "unexpected_failure": "Passwort kann nicht zurückgesetzt werden. Bitte versuchen Sie es später noch einmal."
        }
    },

    "request": {
        "error": "Es gab einen Fehler. Bitte versuche es später noch einmal oder kontaktiere den Support",
        "success_request": "Deine Anfrage wurde erfolgreich versendet",
        "success_data": "Deine Daten wurden erfolgreich gespeichert"
    },

    "validation": {
        "required": "Dieses Feld ist erforderlich",
        "wallet_address": "Bitte gib eine gültige Wallet-Adresse ein",
        "credentials": "E-Mail und/oder Passwort falsch",
        "number": "Bitte gib eine Zahl ein",
        "number_above_0": "Bitte gib eine Zahl größer als 0 ein",
        "number_less_9999": "Bitte gib eine Zahl kleiner als 9999 ein",
        "email": {
            "required": "E-Mail ist ein erforderliches Feld",
            "valid": "Bitte verwende eine gültige E-Mail"
        },
        "password": {
            "required": "Passwort ist ein erforderliches Feld",
            "match": "Passwörter müssen übereinstimmen",
            "requirements": "Muss mindestens 8 Zeichen, 1 Großbuchstaben, 1 Sonderzeichen und 1 Zahl enthalten",
            "wrong": "Falsches Passwort"
        },
        "relogin_required_msg": "Deine Sitzung ist abgelaufen. Bitte melde dich erneut an."
    },

    "welcome_popup": {
        "subline": "Schön, dass du da bist!",
        "headline": "Willkommen im Schwarm",
        "intro": "Bevor du loslegen kannst, möchten wir dich bitten, deine Bitcoin-Wallet-Adresse einzugeben. Wir werden deine Bitcoins an diese Wallet (d. h. eine digitale Geldbörse) senden, die du als Belohnung für deine Dienste zur Sicherung des Bitcoin-Netzwerks erhältst.",
        "no_wallet_yet": "Noch keine Wallet?",
        "tutorial_text": "Dann schaue dir das folgende Video an. Wir erklären, wie du einfach eine neue Wallet erstellen kannst. Nutze unsere sicheren Links unter dem Video, um auf die benötigten Ressourcen zuzugreifen:",
        "safe_links": "Sichere Links",
        "need_help": "Brauchst du Hilfe?",
        "help_text_part_1": "Steckst du an irgendeiner Stelle fest? Schreib uns eine Nachricht an ",
        "help_text_part_2": " und wir helfen dir gerne weiter",
        "start_now": "Jetzt starten!"
    },

    "navigation": {
        "logout": "Abmelden",
        "profile": "Mein Profil",
        "selected_customer": "Ausgewählter Kunde"
    },

    "dashboard": {
        "welcome_message": "Willkommen zurück",
        "your_package": "Dein Paket",
        "next_renewal": "Nächste Erneuerung",
        "contract_number": "Vertragsnummer",
        "start_of_contract": "Vertragsbeginn",
        "contract_duration": "Vertragsdauer",
        "days": "Tage",
        "daily_yield": "Tägliche Ausbeute",
        "monthly_yield": "Monatliche Ausbeute",
        "total_income": "Gesamteinnahmen bis heute",
        "cancel_package": "Paket kündigen",
        "increase_security": "Erhöhe deine Sicherheitsleistung mit neuen Paketen",
        "generated_so_far": "Bisher generiert",
        "difficulty": "Schwierigkeitsgrad",
        "profitability": "Rentabilität",
        "network_power": "Deine Netzwerksicherheitsleistung",
        "transactions": "Transaktionen",
        "empty_transactions_msg": "Du hast noch keine Transaktionen, buche einen Vertrag, um Hashpower für passives Einkommen zu erhalten!",
        "date": "Datum",
        "contract_id": "Vertrags-ID",
        "amount": "Betrag",
        "type": "Typ",
        "lets_go": "Los geht's!",
        "transaction_id_copied": "Transaktions ID wurde in Zwischenablage kopiert"
    },

    "profile": {
        "my_profile": "Mein Profil",
        "name": "Name",
        "email": "E-Mail",
        "last_name": "Nachname",
        "first_name": "Vorname",
        "wallet": "Wallet für Auszahlungen",
        "zip": "Postleitzahl",
        "city": "Stadt",
        "street_number": "Straße, Hausnummer",
        "zip_city": "Postleitzahl, Stadt",
        "country": "Land",
        "change_password": "Passwort ändern",
        "edit_info": "Infos bearbeiten"
    },

    "update_password_popup": {
        "title": "Passwort ändern",
        "current_password": "Aktuelles Passwort",
        "new_password": "Neues Passwort",
        "repeat_password": "Neues Passwort wiederholen"
    },

    "update_info_popup": {
        "title": "Infos bearbeiten"
    },

    "affiliate": {
        "refer_a_friend": "Empfehle einen Freund und erhalte kostenlose Hashpower",
        "affiliate_popup": {
            "title": "Affiliate",
            "sub_title": "Teile deinen Link mit Freunden und erhalte kostenlose Hashpower",
            "your_code": "Dein Partner-Link:",
            "registered_count_notice_singular": "Freund hat sich bereits mit deinem Link registriert. Jetzt bist du dran, Hashpower zu verdienen.",
            "registered_count_notice_plural": "Freunde haben sich bereits mit deinem Link registriert. Jetzt bist du dran, Hashpower zu verdienen.",
            "bronze": "Bronze",
            "silver": "Silber",
            "gold": "Gold",
            "user_advertise": "Empfehle einen Freund",
            "carousel": {
                "share_title": "Teile den Link mit einem Freund",
                "share_description": "Teile den Link mit deinem Freund, damit sich dein Freund einfach registrieren kann und du Hashpower bekommst.",
                "friends_title": "Freunde verwenden deinen Link",
                "friends_description": "Im Bezahlvorgang muss dein Freund deinen Affiliate-Link eingeben.",
                "receive_title": "Erhalte Hashpower",
                "receive_description": "Wenn sich dein Freund mit deinem Link registriert und einen Mining-Plan bucht, erhältst du deine Hashpower!",
                "monthly_reset_title": "Monatlicher Reset",
                "monthly_reset_description": "Deine zusätzliche Hashpower wird zum nächsten Monat aktiv und endet nach einem vollen Monat. Erweitere dein Netzwerk, um Monat für Monat mehr Hashpower zu erhalten!"
            },
            "your_potential_earnings": "Deine potenziellen Einnahmen",
            "affiliate_count": "Affiliate-Zählung",
            "bonus": "Bonus",
            "additional_bonus": "Zusätzlicher Bonus",
            "total_bonus": "Gesamtbonus",
            "your_earning_streak": "Deine Verdienstserie",
            "affiliates_activated": "Aktivierte Affiliates",
            "th_received": "Erhaltene Tera Hashes",
            "choose_month": "Zeige Monat",
            "customer": "Kunde",
            "month": "Monat",
            "current_bonus": "Aktueller Bonus",
            "everything": "Alles",
            "no_data_found": "Keine Daten gefunden",
            "copied": "Kopiert"
        }
    },
    "videos_popup": {
        "video_not_available": "Video nicht verfügbar",
        "title": "Lerninhalte",
        "sub_title": "Mache Dich mit den grundlegenden Informationen zu Bitcoin und Bitcoin-Mining vertraut. Wir erweitern die Inhalte kontinuierlich."
    },
    "support_modal": {
        "title": "Anfrage zur Unterstützung",
        "heading": "Wir sind hier, um Ihnen zu helfen",
        "description": "Worum geht es in Ihrer Anfrage? Bitte geben Sie uns so viele Informationen wie möglich. Ein Teammitglied wird innerhalb der nächsten 24 Stunden mit Ihnen in Verbindung setzen, um Ihnen bei Ihrer Anfrage zu helfen (in der Regel sogar schneller). Die weitere Kommunikation findet per E-Mail statt.",
        "select_category": "Kategorie auswählen",
        "payment": "Zahlung",
        "technical": "Technisch",
        "general": "Allgemein",
        "describe_request": "Bitte beschreiben Sie Ihr Anliegen",
        "send": "Senden",
        "request_sent": "Ihre Anfrage wurde erfolgreich gesendet.",
        "request_error": "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns per Mail."
    },
    "feedback": {
        "video_message": "Herzlichen Glückwunsch! Sie haben erfolgreich Ihr erstes Bitcoin-Wissen gesammelt! Wie würden Sie unsere Videos bewerten?",
        "login_message": "Danke, dass Ihnen unser Produkt gefällt. Wie würden Sie unser Dashboard bewerten?",
        "address_message": "Herzlichen Glückwunsch! Sie haben Ihren ersten Schritt in die Welt von Bitcoin erfolgreich gemeistert! Wie würden Sie Ihre bisherigen Erfahrungen bewerten?",
        "how_can_we_do_better": "Bitte teilen Sie uns Ihre Meinung zu Ihrer Bewertung mit: Wie können wir besser werden?",
        "send": "Senden",
        "feedback_sent": "Feedback gesendet! Vielen Dank, dass Sie sich die Zeit genommen haben, uns Ihr Feedback zu schicken.",
        "unable_to_send": "Sie können kein Feedback senden. Bitte versuchen Sie es später noch einmal.",
        "full_rating": {
            "first_line": "Wow, vielen Dank!",
            "second_line": "Würden Sie uns Ihren positiven Eindruck von Trustpilot mitteilen?",
            "third_line": "Sie würden uns sehr helfen:",
            "rate_on_trustpilot": "Bewertung auf Trustpilot"
        }
    }
}
