<script setup>
import AuthorizedLayout from "@/layouts/AuthorizedLayout.vue";
</script>

<template>
	<AuthorizedLayout>
		<template v-slot:title>
			<router-link to="/" class="m-0 text-white">
				<font-awesome-icon class="display-6 me-2" :icon="['fas', 'arrow-left']" />
				<span class="h1 fw-normal m-0">{{ $t("back") }}</span>
			</router-link>
		</template>
		<div v-if="user.customers.length > 0" class="container mt-5">
			<div class="bg-light rounded p-4 overflow-hidden">
				<div class="row">
					<div class="col-auto">
						<img id="avatar" :src="require('@/assets/user-profile.png')" width="180" class="ms-n5 mt-n5" />
					</div>
					<div class="col-auto">
						<p class="text-secondary mb-2">{{ $t("profile.my_profile") }}</p>
						<h2 class="mb-0 text-white">
							<span class="fw-normal">{{ selectedCustomer.customer_name }}</span>
						</h2>
					</div>
				</div>
				<div class="row gx-5">
					<div class="col-md-6">
						<table class="table-dark table-xs text-body mt-5 mb-3 w-100">
							<tbody>
								<tr>
									<td class="pb-2 text-white">{{ $t("profile.name") }}:</td>
									<td class="text-end">{{ selectedCustomer.customer_name }}</td>
								</tr>
								<tr class="border-top border-secondary">
									<td class="py-2 text-white">{{ $t("profile.email") }}:</td>
									<td class="text-end">{{ selectedCustomer.customer_email }}</td>
								</tr>
								<tr class="border-top border-secondary">
									<td class="pt-2 text-white">{{ $t("profile.wallet") }}:</td>
									<td class="text-end position-relative">
										<div class="pe-4 pt-2">
											{{ showWallet ? selectedCustomer.wallet_address : censorWallet(selectedCustomer.wallet_address) }}
										</div>

										<div class="position-absolute end-0 top-0 bottom-0 d-flex align-items-center pt-2">
											<div @click="showWallet = !showWallet" class="cursor-pointer">
												<font-awesome-icon
													class="text-secondary cursor-pointer"
													:icon="['fas', showWallet ? 'eye-slash' : 'eye']"
												/>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="col-md-6">
						<table class="table-dark table-xs text-body mt-5 mb-3 w-100">
							<tbody>
								<tr>
									<td class="pb-2 text-white">{{ $t("profile.street_number") }}:</td>
									<td class="text-end">
										{{ selectedCustomer.customer_address }}
									</td>
								</tr>
								<tr class="border-top border-secondary">
									<td class="py-2 text-white">{{ $t("profile.zip_city") }}:</td>
									<td class="text-end">
										{{ selectedCustomer.customer_zip }},
										{{ selectedCustomer.customer_city }}
									</td>
								</tr>
								<tr class="border-top border-secondary">
									<td class="pt-2 text-white">{{ $t("profile.country") }}:</td>
									<td class="text-end">
										{{ selectedCustomer.customer_country }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="text-end mt-4">
						<a
							href="#"
							data-bs-toggle="modal"
							data-bs-target="#updatePassword"
							class="btn btn-outline-primary me-3 m-w-100"
							@click="resetDialogState"
							>{{ $t("profile.change_password") }}</a
						>
						<a
							href="#"
							data-bs-toggle="modal"
							data-bs-target="#updateInfo"
							class="btn btn-outline-primary m-w-100 mt-4 mt-sm-0"
							@click="resetDialogState"
							>{{ $t("profile.edit_info") }}</a
						>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade"
			id="updatePassword"
			tabindex="-1"
			data-bs-backdrop="true"
			aria-labelledby="updatePasswordLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<button
						type="button"
						class="btn-close btn-close-white"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
					<div class="modal-body bg-dark text-white">
						<h1>{{ $t("update_password_popup.title") }}</h1>
						<Form @submit="updatePassword" :validation-schema="updatePasswordSchema">
							<fieldset>
								<label for="old_password" class="form-label"
									><small>{{ $t("update_password_popup.current_password") }}*</small></label
								>
								<div class="mb-3">
									<Field
										type="password"
										class="form-control bg-dark text-white"
										id="old_password"
										name="old_password"
									/>
									<ErrorMessage name="old_password" class="text-danger d-inline-block mt-1" />
								</div>
								<hr />
								<label for="new_password" class="form-label"
									><small>{{ $t("update_password_popup.new_password") }}*</small></label
								>
								<div class="mb-3">
									<Field
										type="password"
										class="form-control bg-dark text-white"
										id="new_password"
										name="new_password"
									/>
									<ErrorMessage name="new_password" class="text-danger d-inline-block mt-1" />
								</div>
								<label for="new_password_repeat" class="form-label"
									><small>{{ $t("update_password_popup.repeat_password") }}*</small></label
								>
								<div class="mb-3">
									<Field
										type="password"
										class="form-control bg-dark text-white"
										id="new_password_repeat"
										name="new_password_repeat"
									/>
									<ErrorMessage name="new_password_repeat" class="text-danger d-inline-block mt-1" />
								</div>

								<button class="btn btn-primary mt-4" type="submit">
									{{ $t("confirm") }}
								</button>
							</fieldset>
							<div
								class="state position-absolute start-0 top-0 end-0 bottom-0 d-flex justify-content-center align-items-center bg-dark"
							>
								<div class="loading text-center position-absolute">
									<div class="icon display-1 mb-3">
										<font-awesome-icon
											class="bg-dark text-white"
											:icon="['fas', 'circle-notch']"
											size="2xl"
											spin
											spin-reverse
										/>
									</div>
								</div>
								<div class="success-message text-center position-absolute">
									<div class="icon display-1 mb-3">
										<font-awesome-icon
											class="text-success bg-dark"
											:icon="['fas', 'circle-check']"
											size="2xl"
										/>
									</div>
									<div class="message">
										{{ $t("request.success_data") }}
									</div>
								</div>
								<div class="failure-message text-center position-absolute">
									<div class="icon display-1 mb-3">
										<font-awesome-icon
											class="text-danger bg-dark"
											:icon="['fas', 'circle-xmark']"
											size="2xl"
										/>
									</div>
									<div class="message">
										{{ $t("request.error") }}
									</div>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade"
			id="updateInfo"
			tabindex="-1"
			data-bs-backdrop="true"
			aria-labelledby="updateInfoLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<button
						type="button"
						class="btn-close btn-close-white"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
					<div class="modal-body bg-dark text-white">
						<h1>{{ $t("update_info_popup.title") }}</h1>
						<Form @submit="updateInfos" :validation-schema="schema">
							<fieldset>
								<label for="name" class="form-label"
									><small>{{ $t("profile.name") }}*</small></label
								>
								<div class="mb-3">
									<Field
										type="text"
										class="form-control bg-dark text-white"
										id="name"
										name="name"
										v-model="profileForm.customer_name"
									/>
									<ErrorMessage name="name" class="text-danger d-inline-block mt-1" />
								</div>
								<label for="address" class="form-label"
									><small>{{ $t("profile.street_number") }}*</small></label
								>
								<div class="mb-3">
									<Field
										type="text"
										class="form-control bg-dark text-white"
										id="customer_address"
										name="address"
										v-model="profileForm.customer_address"
									/>
									<ErrorMessage name="address" class="text-danger d-inline-block mt-1" />
								</div>
								<label for="postcode" class="form-label"
									><small>{{ $t("profile.zip") }}*</small></label
								>
								<div class="mb-3">
									<Field
										type="text"
										class="form-control bg-dark text-white"
										id="customer_postcode"
										name="zip"
										v-model="profileForm.customer_zip"
									/>
									<ErrorMessage name="zip" class="text-danger d-inline-block mt-1" />
								</div>
								<label for="city" class="form-label"
									><small>{{ $t("profile.city") }}*</small></label
								>
								<div class="mb-3">
									<Field
										type="text"
										class="form-control bg-dark text-white"
										id="customer_city"
										name="city"
										v-model="profileForm.customer_city"
									/>
									<ErrorMessage name="city" class="text-danger d-inline-block mt-1" />
								</div>
								<label for="country" class="form-label"
									><small>{{ $t("profile.country") }}*</small></label
								>
								<div class="mb-3">
									<Field
										type="text"
										class="form-control bg-dark text-white"
										id="customer_country"
										name="country"
										v-model="profileForm.customer_country"
									/>
									<ErrorMessage name="country" class="text-danger d-inline-block mt-1" />
								</div>
								<hr />
								<label for="wallet" class="form-label"
									><small>{{ $t("profile.wallet") }}*</small></label
								>
								<div class="mb-3">
									<div class="position-relative">
										<Field
											:type="showWallet ? 'text' : 'password'"
											class="form-control bg-dark text-white"
											id="wallet"
											name="wallet"
											v-model="profileForm.wallet_address"
										/>
										<div
											class="position-absolute end-0 top-0 bottom-0 d-flex align-items-center me-2"
										>
											<div @click="showWallet = !showWallet" class="cursor-pointer">
												<font-awesome-icon
													class="text-secondary cursor-pointer"
													:icon="['fas', showWallet ? 'eye-slash' : 'eye']"
												/>
											</div>
										</div>
									</div>
									<ErrorMessage name="wallet" class="text-danger d-inline-block mt-1" />
								</div>
								<button class="btn btn-primary mt-4" type="submit">
									{{ $t("confirm") }}
								</button>
							</fieldset>
							<div
								class="state position-absolute start-0 top-0 end-0 bottom-0 d-flex justify-content-center align-items-center bg-dark"
							>
								<div class="loading text-center position-absolute">
									<div class="icon display-1 mb-3">
										<font-awesome-icon
											class="bg-dark text-white"
											:icon="['fas', 'circle-notch']"
											size="2xl"
											spin
											spin-reverse
										/>
									</div>
								</div>
								<div class="success-message text-center position-absolute">
									<div class="icon display-1 mb-3">
										<font-awesome-icon
											class="text-success bg-dark"
											:icon="['fas', 'circle-check']"
											size="2xl"
										/>
									</div>
									<div class="message">
										{{ $t("request.success_data") }}
									</div>
								</div>
								<div class="failure-message text-center position-absolute">
									<div class="icon display-1 mb-3">
										<font-awesome-icon
											class="text-danger bg-dark"
											:icon="['fas', 'circle-xmark']"
											size="2xl"
										/>
									</div>
									<div class="message">
										{{ $t("request.error") }}
									</div>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	</AuthorizedLayout>
</template>

<script>
import { useUserStore } from "@/js/user.js";
import { resetDialogState } from "@/js/common.js";
import { computed, ref, watch } from "vue";
import { Form, Field, ErrorMessage, configure, ValidationProvider } from "vee-validate";
import * as yup from "yup";
import { i18n } from "@/main";

configure({
	validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
	validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
	validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
	validateOnModelUpdate: false // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

const schema = yup.object({
	name: yup.string().required(() => i18n.global.t("validation.required")),
	address: yup.string().required(() => i18n.global.t("validation.required")),
	zip: yup.string().required(() => i18n.global.t("validation.required")),
	country: yup.string().required(() => i18n.global.t("validation.required")),
	city: yup.string().required(() => i18n.global.t("validation.required")),
	wallet: yup
		.string()
		.required(() => i18n.global.t("validation.required"))
		.matches(/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,99}$/, () => i18n.global.t("validation.wallet_address"))
});

const updatePasswordSchema = yup.object({
	old_password: yup.string().required(() => i18n.global.t("validation.required")),
	new_password: yup
		.string()
		.required(() => i18n.global.t("validation.required"))
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*[\]{}()?"\\,><':;|_~`=+-])[a-zA-Z\d!@#$%^&*[\]{}()?"\\,><':;|_~`=+-]{8,}$/,
			() => i18n.global.t("validation.password.requirements")
		),
	new_password_repeat: yup
		.string()
		.required(() => i18n.global.t("validation.required"))
		.oneOf([yup.ref("new_password")], () => i18n.global.t("validation.password.match"))
});

export default {
	data() {
		return {
			user: null,
			showWallet: ref(false),
			name: "",
			schema: ref(null),
			selectedCustomer: null,
			profileForm: {
				customer_name: "",
				customer_address: "",
				customer_zip: "",
				customer_city: "",
				customer_country: "",
				wallet_address: "",
			}
		};
	},
	methods: {
		censorWallet(wallet) {
			return "*".repeat(6) + wallet.slice(-4);
		},
		async updateInfos(values, eventHandler) {
			let userStore = useUserStore();
			eventHandler.evt.target.classList.add("submitted");

			let customerData = {
				name: values["name"],
				contact: this.selectedCustomer.customer_contact,
				email: this.selectedCustomer.customer_email,
				address: values["address"],
				zip: values["zip"],
				city: values["city"],
				phone: this.selectedCustomer.customer_phone,
				country: values["country"],
				id: this.selectedCustomer.customer_id
			};

			let walletData = {
				wallet_address: values["wallet"],
				customer_id: this.selectedCustomer.customer_id
			};

			userStore
				.updateData(customerData, walletData)
				.then(response => {
					if (response == 204) {
						eventHandler.evt.target.classList.add("success");
						userStore.isAuthenticated().then(response => {
							this.user = userStore.$state.user;
						});
					} else eventHandler.evt.target.classList.add("failure");

					setTimeout(() => {
						eventHandler.evt.target.classList.remove("submitted");
						eventHandler.evt.target.classList.remove("success");
						eventHandler.evt.target.classList.remove("failure");
					}, 4000);
				})
				.catch(error => {
					console.log(error);
				});
		},
		async updatePassword(values, eventHandler) {
			let userStore = useUserStore();
			eventHandler.evt.target.classList.add("submitted");

			let passwordData = {
				old_password: values["old_password"],
				new_password: values["new_password"]
			};

			userStore
				.updatePassword(passwordData)
				.then(response => {
					if (response) {
						eventHandler.evt.target.classList.add("success");
					} else {
						eventHandler.setFieldError("old_password", i18n.global.t("validation.password.wrong"));
						eventHandler.evt.target.classList.remove("submitted");
					}

					setTimeout(() => {
						eventHandler.evt.target.classList.remove("submitted");
						eventHandler.evt.target.classList.remove("success");
						eventHandler.evt.target.classList.remove("failure");
					}, 4000);
				})
				.catch(error => {
					console.log(error);
				});
		},
		setFormData(customer) {
			this.profileForm = customer;
		}
	},
	beforeMount() {
		const user = useUserStore();
		this.user = user.$state.user;

		this.selectedCustomer = user.$state.user.selected_customer;
		this.setFormData(this.selectedCustomer);

		const selectedCustomer = computed(() => user.getSelectedCustomer)

		watch(selectedCustomer, async (newVal, _) => {
			this.selectedCustomer = newVal
			this.setFormData(newVal);
		});
	}
};
</script>
