import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import { router } from "@/js/router";
import PrimeVue from "primevue/config";
import "./scss/main.scss";
import { createI18n } from "vue-i18n";
import messages from "./js/lang/messages";
import Particles from "@tsparticles/vue3";
import { loadSlim } from "@tsparticles/slim";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import * as Sentry from "@sentry/vue";
import {
	faCirclePlay
} from "@fortawesome/free-regular-svg-icons";
library.add(
	faCirclePlay
);
import {
	faEye,
	faRightFromBracket,
	faUser,
	faUserSecret,
	faArrowRight,
	faRightLong,
	faGear,
	faCircleInfo,
	faArrowLeft,
	faEyeSlash,
	faChevronUp,
	faCircleCheck,
	faCircleNotch,
	faCircleXmark,
	faXmark,
	faCheck,
	faAngleDown,
	faCaretDown,
	faGraduationCap
} from "@fortawesome/free-solid-svg-icons";
library.add(
	faEye,
	faUserSecret,
	faRightFromBracket,
	faUser,
	faArrowRight,
	faRightLong,
	faGear,
	faCircleInfo,
	faArrowLeft,
	faEyeSlash,
	faChevronUp,
	faCircleCheck,
	faCircleNotch,
	faCircleXmark,
	faXmark,
	faCheck,
	faAngleDown,
	faCaretDown,
	faGraduationCap
);

export const i18n = createI18n({
	locale: "de",
	fallbackLocale: "de",
	messages
});

const app = createApp(App);

Sentry.init({
	app,
	dsn: "https://66f050a7462c7fc524843d19c8fcd368@o1068296.ingest.us.sentry.io/4507231679479808",
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(createPinia());
app.use(router);
app.use(PrimeVue);
app.use(i18n);
app.use(Particles, {
	init: async engine => {
		// await loadFull(engine); // you can load the full tsParticles library from "tsparticles" if you need it
		await loadSlim(engine); // or you can load the slim version from "@tsparticles/slim" if don't need Shapes or Animations
	}
});
app.mount("#app");
