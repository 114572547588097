<template class="z-index-3">
	<div
		class="modal fade"
		ref="feedbackModal"
		id="feedbackModal"
		tabindex="-1"
		data-bs-backdrop="static"
		aria-labelledby="feedbackModalLabel"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content feedback-container text-center">
				<div>
					<img class="header-img" src="@/assets/feedback/target-range.svg" />
				</div>
				<div class="modal-body bg-dark text-white position-relative text-center">
					<p v-if="feedbackTypes.video === feedbackType" class="text-center feedback-msg fw-light">
						{{ $t("feedback.video_message") }}
					</p>
					<p v-if="feedbackTypes.address === feedbackType" class="text-center feedback-msg fw-light">
						{{ $t("feedback.address_message") }}
					</p>
					<p v-if="feedbackTypes.login === feedbackType" class="text-center feedback-msg fw-light">
						{{ $t("feedback.login_message") }}
					</p>

					<div class="d-flex justify-content-center mb-4 mt-4">
						<div class="border px-4 py-3 rounded-3 border-black border-2">
							<span v-for="n in 5" :key="n" :class="{ 'me-4': n !== 5 }" @click="selectStar(n)">
								<StarIcon class="cursor-pointer" :isFilled="n <= rating" />
							</span>
						</div>
					</div>

					<div class="pb-1" v-if="rating >= 1 && rating <= 4">
						<p class="fw-light mb-4">{{ $t("feedback.how_can_we_do_better") }}</p>
						<input
							v-model="suggestion"
							class="w-full w-100 rounded border bg-light border-light p-3 text-white"
						/>
						<button @click="() => sendSuggestion()" class="btn btn-primary text-white mt-4" type="button">
							{{ $t("feedback.send") }}
						</button>
					</div>

					<div class="pb-1" v-if="rating >= 5">
						<p class="fw-light mb-0 me-1">
							{{ $t("feedback.full_rating.first_line") }}
							<img class="emoji" src="@/assets/feedback/star_struck.svg" />
						</p>
						<p class="fw-light mb-0">{{ $t("feedback.full_rating.second_line") }}</p>
						<p class="fw-light mb-0">{{ $t("feedback.full_rating.third_line") }}</p>
						<a
							@click="sendFullRating"
							:href="trustpilot_link"
							target="_blank"
							class="btn btn-success text-white mt-4 fw-normal"
							type="button"
						>
							{{ $t("feedback.full_rating.rate_on_trustpilot") }}
							<font-awesome-icon class="text-white" :icon="['fa', 'right-long']" size="sm" />
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useUserStore } from "@/js/user.js";
import { feedbackTypes, TRUSTPITOL_LINK } from "@/js/constants.js";
import { Modal } from "bootstrap/dist/js/bootstrap";
import { ref } from "vue";
import StarIcon from "@/components/StarIcon.vue";
import Toast from "@/js/toast";
import { i18n } from "@/main";

export default {
	components: {
		StarIcon
	},
	props: {
		showModal: {
			type: Boolean
		},
		feedbackType: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			feedbackModal: ref(null),
			rating: 0,
			suggestion: "",
			error: "",
			feedbackTypes,
			trustpilot_link: TRUSTPITOL_LINK
		};
	},
	methods: {
		hideFeedback() {
			this.$refs["feedbackModal"].hide();
		},
		selectStar(index) {
			this.rating = index;
		},
		sendSuggestion() {
			const userStore = useUserStore();

			userStore
				.saveFeedback({
					feedback_type: this.feedbackType,
					feedback: this.suggestion,
					rating: this.rating,
					customer_id: userStore.user.selected_customer.customer_id
				})
				.then(res => {
					if (res === 201) {
						Toast.addToast(i18n.global.t("feedback.feedback_sent"));
						this.feedbackModal.hide();
					} else {
						Toast.addToast(i18n.global.t("feedback.unable_to_send"));
						this.feedbackModal.hide();
					}
				});
		},
		sendFullRating() {
			this.suggestion = "";
			this.sendSuggestion();
		},
	},
	watch: {
		showModal: function (newVal, _) {
			this.feedbackModal = new Modal(this.$refs.feedbackModal);

			if (newVal) {
				this.feedbackModal.show();
			}
		}
	}
};
</script>
