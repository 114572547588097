<template>
	<iframe
		v-if="isExternal"
		class="w-100 h-100 rounded rounded-3"
		:src="url"
		frameborder="0"
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
		referrerpolicy="strict-origin-when-cross-origin"
		allowfullscreen
	></iframe>
	<video v-if="!isExternal" class="w-100 h-100" autoplay muted loop>
		<source :src="url" />
	</video>
</template>

<script>
export default {
	props: {
		url: {
			type: String,
			required: true
		},
		isExternal: {
			type: Boolean
		}
	}
};
</script>
