<script setup>
import AuthorizedLayout from "@/layouts/AuthorizedLayout.vue";
import moment from "moment";
</script>

<template>
	<AuthorizedLayout>
		<template v-slot:title>
			<h1 class="m-0">
				<span class="fw-normal">{{ $t("dashboard.welcome_message") }},</span>
				{{ user.prename }}!
			</h1>
		</template>

		<div v-if="!isReferMsgHidden" class="container cursor-pointer mt-5 affiliate-container">
			<div class="border border-primary rounded bg-primary text-primary" style="--bs-bg-opacity: 0.08">
				<div class="msg-container" id="affiliate-hint">
					<div class="w-100 p-4" data-bs-toggle="modal" data-bs-target="#affiliateModal">
						<img width="32" :src="require('/src/assets/refer-icon.png')" />

						<span class="px-3">
							{{ $t("affiliate.refer_a_friend") }}
						</span>
					</div>
					<font-awesome-icon
						class="float-end fs-3 cursor-pointer ms-auto d-absolute p-4"
						@click="isReferMsgHidden = !isReferMsgHidden"
						:icon="['fas', 'xmark']"
					/>
				</div>
			</div>
		</div>
		<div class="container mt-5">
			<div v-for="contract in user.contracts.filter(val => val.days_left)" :key="contract.contract_id">
				<div class="contract-container bg-light rounded overflow-hidden mb-4">
					<div class="row p-4 g-3">
						<div class="col-md-6 col-xl-3">
							<p class="text-secondary mb-2">
								{{ $t("dashboard.your_package") }}
							</p>
							<h2 class="mb-0 text-white">{{ contract.plan_name }}</h2>
						</div>
						<div class="col-md-6 col-xl-2">
							<p class="text-secondary mb-2">Hashrate</p>
							<h2 class="mb-0 text-white">{{ contract.tera_hashes }} TH/s</h2>
						</div>
						<div class="col-lg-9 col-xl-5 d-flex flex-column">
							<p class="text-secondary mb-0">
								{{ $t("dashboard.next_renewal") }}
							</p>
							<div class="progress my-auto bg-info rounded-pill">
								<div
									class="progress-bar"
									role="progressbar"
									:style="`width: ${(100 * contract.days_left) / contract.duration_days}%`"
									:aria-valuenow="contract.days_left"
									aria-valuemin="0"
									aria-valuemax="100"
								></div>
							</div>
						</div>
						<div class="mt-4 col-xl-2 ms-auto align-self-center ps-xl-4">
							<button
								class="btn btn-primary btn-toggle w-100 collapsed px-0"
								type="button"
								data-bs-toggle="collapse"
								:data-bs-target="`#collapseDetails-${contract.contract_id}`"
								aria-expanded="false"
								:aria-controls="`collapseDetails-${contract.contract_id}`"
							>
								{{ $t("details") }} <font-awesome-icon :icon="['fas', 'chevron-up']" />
							</button>
						</div>
					</div>
					<div class="collapse" :id="`collapseDetails-${contract.contract_id}`">
						<div class="card card-body bg-light rounded-0 px-4">
							<div class="row g-4">
								<div class="col-md-6">
									<table class="table-dark table-xs text-body w-100">
										<tbody>
											<tr>
												<td class="pb-2 text-white">
													{{ $t("dashboard.contract_number") }}
												</td>
												<td class="text-end">{{ contract.contract_id }}</td>
											</tr>
											<tr class="border-top border-bottom border-secondary">
												<td class="py-2 text-white">
													{{ $t("dashboard.start_of_contract") }}
												</td>
												<td class="text-end">
													{{ moment(contract.start_date, "yyyy-MM-DD").format("DD.MM.yyyy") }}
												</td>
											</tr>
											<tr>
												<td class="pt-2 text-white">
													{{ $t("dashboard.contract_duration") }}
												</td>
												<td class="text-end">
													{{ contract.duration_days }}
													{{ $t("dashboard.days") }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="col-md-6">
									<table class="table-dark table-xs text-body w-100">
										<tbody>
											<tr>
												<td class="pb-2 text-white">
													{{ $t("dashboard.daily_yield") }}
												</td>
												<td class="text-end">
													{{ user.payouts_stats[0].daily_revenue_btc.toFixed(12) }} BTC
												</td>
											</tr>
											<tr class="border-top border-bottom border-secondary">
												<td class="py-2 text-white">
													{{ $t("dashboard.monthly_yield") }}
												</td>
												<td class="text-end">
													{{ user.payouts_stats[0].monthly_revenue_btc.toFixed(12) }} BTC
												</td>
											</tr>
											<tr>
												<td class="pt-2 text-white">
													{{ $t("dashboard.total_income") }}
												</td>
												<td class="text-end">
													{{ user.payouts_stats[0].total_payout_btc.toFixed(12) }} BTC
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div @click="packageDelete = contract.contract_id" class="ms-auto mt-4">
								<a
									href="#"
									data-bs-toggle="modal"
									data-bs-target="#cancelPlanModal"
									class="btn btn-small btn-outline-secondary text-decoration-none text-body"
									@click="resetDialogState"
									>{{ $t("dashboard.cancel_package") }}</a
								>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="text-center my-5 py-3">
				<a
					href="#"
					data-bs-toggle="modal"
					data-bs-target="#addPlanModal"
					class="cta-link d-inline-block position-relative text-white"
				>
					<h2 class="mb-0 fw-normal d-inline-block pe-2">
						{{ $t("dashboard.increase_security") }}
					</h2>
					<font-awesome-icon :icon="['fas', 'arrow-right']" />
				</a>
			</div>
			<AddPackage/>
			<div class="row g-4">
				<div class="col-lg-4">
					<div class="bg-light rounded p-4 h-100 d-flex flex-column">
						<div class="d-flex justify-content-between">
							<div class="display-6 text-secondary">
								{{ $t("dashboard.generated_so_far") }}
							</div>
							<a href="#" data-bs-toggle="modal" data-bs-target="#infoModalSavings" class="text-body"
								><font-awesome-icon :icon="['fas', 'circle-info']"
							/></a>
						</div>
						<table class="table-dark table-xs text-body mt-5 mb-3 w-100">
							<tbody>
								<tr>
									<td class="pb-2 text-white">
										{{ $t("dashboard.daily_yield") }}
									</td>
									<td class="text-end">
										{{ user.payouts_stats[0].daily_revenue_btc.toFixed(12) }} BTC
									</td>
								</tr>
								<tr class="border-top border-secondary">
									<td class="pt-2 text-white">
										{{ $t("dashboard.monthly_yield") }}
									</td>
									<td class="text-end">
										{{ user.payouts_stats[0].monthly_revenue_btc.toFixed(12) }} BTC
									</td>
								</tr>
							</tbody>
						</table>
						<div class="display-4 text-white fw-bold mt-auto">
							{{ user.payouts_stats[0].total_payout_btc.toFixed(12) }}
							<span class="text-secondary">BTC</span>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="bg-light rounded p-4 h-100 d-flex flex-column">
						<div class="d-flex justify-content-between">
							<div class="display-6 text-secondary">
								{{ $t("dashboard.profitability") }}
							</div>
							<a href="#" data-bs-toggle="modal" data-bs-target="#infoModalDifficulty" class="text-body"
								><font-awesome-icon :icon="['fas', 'circle-info']"
							/></a>
						</div>
						<div class="position-relative">
							<Chart type="line" :data="chartDataDifficulty" :options="chartOptions" class="py-2" />
							<div class="d-none">
								<Chart
									type="line"
									:data="chartDataProfitability"
									:options="chartOptions"
									class="position-absolute start-0 top-0 w-100 h-100"
								/>
							</div>
						</div>
						<div class="display-4 text-white fw-bold mt-auto">
							{{ chartDataDifficulty.datasets[0].data[29].toFixed(2) }}
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="bg-light rounded p-4 h-100 d-flex flex-column">
						<div class="d-flex justify-content-between">
							<div class="display-6 text-secondary">
								{{ $t("dashboard.network_power") }}
							</div>
							<a href="#" data-bs-toggle="modal" data-bs-target="#infoModalComputing" class="text-body"
								><font-awesome-icon :icon="['fas', 'circle-info']"
							/></a>
						</div>
						<div class="display-4 text-white fw-bold mt-auto">
							{{ computingPower }} <span class="text-secondary">TH/s</span>
						</div>
					</div>
				</div>
			</div>
			<div id="transactions-container" class="border border-light p-4 my-5">
				<div class="display-6 text-secondary">
					{{ $t("dashboard.transactions") }}
				</div>
				<div v-if="!transactions['items'] || transactions['items'].length === 0">
					<p class="text-center mx-0 mx-sm-5 my-5 px-0 px-sm-5 m-sm-5 display-6 fw-normal">
						{{ $t("dashboard.empty_transactions_msg") }}
					</p>
					<img
						:src="require('/src/assets/no-transactions.png')"
						class="d-block col-8 col-md-5 col-lg-2 m-auto mt-4 mb-2"
						alt="ReceiveHashPowerImage"
					/>
				</div>
				<div v-if="transactions['items'] && transactions['items'].length > 0">
					<DataTable
						:value="transactions['items']"
						:loading="transactions['loading']"
						lazy
						:totalRecords="transactions['total']"
						paginator
						:rows="10"
						:rowsPerPageOptions="[5, 10, 20, 50]"
						@page="onPageSelection($event)"
						tableClass="table table-dark mt-4"
					>
						<Column field="book_date" :header="$t('dashboard.date')">
							<template #body="slotProps">
								{{ moment(slotProps.data.book_date, "yyyy-MM-DD").format("DD.MM.yyyy") }}
							</template>
						</Column>
						<Column field="book_id" header="ID">
							<template #body="slotProps">
								<abbr @click="copyBookID(slotProps.data.book_id)" title="Book ID" class="cursor-pointer" v-if="slotProps.data.book_id">
									{{ limitBookIdLength(slotProps.data.book_id) }}
								</abbr>
								<span v-if="!slotProps.data.book_id">
									-
								</span>
							</template>
						</Column>
						<Column class="rem-1" field="contract_id" :header="$t('dashboard.contract_id')">
							<template #body="slotProps">
								{{ slotProps.data.contract_id ?? "-" }}
							</template>
						</Column>
						<Column field="daily_payout" :header="$t('dashboard.amount')">
							<template #body="slotProps">
								<span class="text-success">{{ slotProps.data.daily_payout }} BTC</span>
							</template>
						</Column>
						<Column class="text-capitalize" field="book_type" :header="$t('dashboard.type')"></Column>
					</DataTable>
				</div>
			</div>
		</div>
		<div
			class="modal fade"
			id="cancelPlanModal"
			tabindex="-1"
			data-bs-backdrop="true"
			aria-labelledby="cancelPlanModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content bg-dark">
					<button
						type="button"
						@click="resetForm"
						class="btn-close btn-close-white"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
					<div class="modal-body text-white">
						<div class="display-5">{{ $t("cancel_popup.subline") }}</div>
						<h1>{{ $t("cancel_popup.headline") }}</h1>
						<p>{{ $t("cancel_popup.bodytext") }}</p>
						<Form @submit="cancelPackage">
							<fieldset>
								<div class="mb-3">
									<label for="other" class="form-label"
										><small>{{ $t("cancel_popup.textarea_reason") }}</small></label
									>
									<Field
										as="textarea"
										type="text"
										id="other"
										name="other"
										rows="5"
										class="form-control bg-dark text-white"
									/>
									<Field as="hidden" name="id" v-model="packageDelete"></Field>
								</div>
								<button class="btn btn-primary mt-4" type="submit">
									{{ $t("confirm") }}
								</button>
							</fieldset>
							<div
								class="state position-absolute start-0 top-0 end-0 bottom-0 d-flex justify-content-center align-items-center bg-dark"
							>
								<div class="loading text-center position-absolute">
									<div class="icon display-1 mb-3">
										<font-awesome-icon
											class="bg-dark text-white"
											:icon="['fas', 'circle-notch']"
											size="2xl"
											spin
											spin-reverse
										/>
									</div>
								</div>
								<div class="success-message text-center position-absolute">
									<div class="icon display-1 mb-3">
										<font-awesome-icon
											class="text-success bg-dark"
											:icon="['fas', 'circle-check']"
											size="2xl"
										/>
									</div>
									<div class="message">
										{{ $t("request.success_request") }}
									</div>
								</div>
								<div class="failure-message text-center position-absolute">
									<div class="icon display-1 mb-3">
										<font-awesome-icon
											class="text-danger bg-dark"
											:icon="['fas', 'circle-xmark']"
											size="2xl"
										/>
									</div>
									<div class="message">
										{{ $t("request.error") }}
									</div>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade"
			id="infoModalSavings"
			tabindex="-1"
			data-bs-backdrop="true"
			aria-labelledby="infoModalSavingsLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<button
						type="button"
						class="btn-close btn-close-white"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
					<div class="modal-body bg-dark text-white">
						<h1>{{ $t("info_savings_popup.headline") }}</h1>
						<p>{{ $t("info_savings_popup.bodytext_1") }}</p>
						<p>{{ $t("info_savings_popup.bodytext_2") }}</p>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade"
			id="infoModalDifficulty"
			tabindex="-1"
			data-bs-backdrop="true"
			aria-labelledby="infoModalDifficultyLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<button
						type="button"
						class="btn-close btn-close-white"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
					<div class="modal-body bg-dark text-white">
						<h1>{{ $t("info_difficulty_popup.headline") }}</h1>
						<p>{{ $t("info_difficulty_popup.bodytext_1") }}</p>
						<p>{{ $t("info_difficulty_popup.bodytext_2") }}</p>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade"
			id="infoModalComputing"
			tabindex="-1"
			data-bs-backdrop="true"
			aria-labelledby="infoModalComputingLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<button
						type="button"
						class="btn-close btn-close-white"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
					<div class="modal-body bg-dark text-white">
						<h1>{{ $t("info_computing_popup.headline") }}</h1>
						<p>{{ $t("info_computing_popup.bodytext") }}</p>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal"
			id="enterWalletModal"
			tabindex="-1"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="enterWalletModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-body">
						<div class="display-5">{{ $t("welcome_popup.subline") }}</div>
						<h1 class="text-uppercase">{{ $t("welcome_popup.headline") }}</h1>
						<p>{{ $t("welcome_popup.intro") }}</p>
						<Form @submit="updateWallet" :validation-schema="schemaWallet">
							<fieldset class="mb-4">
								<div class="d-flex">
									<div id="walletField" class="position-relative">
										<Field type="text" class="form-control h-100" id="wallet" name="wallet" />
									</div>
									<button class="btn btn-primary ms-3" type="submit">
										{{ $t("dashboard.lets_go") }}
									</button>
								</div>
								<ErrorMessage name="wallet" class="text-danger d-block pt-1" />
							</fieldset>
							<div
								class="state position-absolute start-0 top-0 end-0 bottom-0 d-flex justify-content-center align-items-center"
							>
								<div class="loading text-center position-absolute">
									<div class="icon display-1 mb-3">
										<font-awesome-icon
											class="bg-white"
											:icon="['fas', 'circle-notch']"
											size="2xl"
											spin
											spin-reverse
										/>
									</div>
								</div>
								<div class="success-message text-center position-absolute">
									<div class="icon display-1 mb-3">
										<font-awesome-icon
											class="text-success bg-white"
											:icon="['fas', 'circle-check']"
											size="2xl"
										/>
									</div>
									<div class="message">
										{{ $t("request.success_data") }}
										<br />
										<button
											class="btn btn-primary mt-3"
											id="btn-start-now"
											data-bs-dismiss="modal"
											type="submit"
											@click="showFeedback = true"
										>
											{{ $t("welcome_popup.start_now") }}
										</button>
									</div>
								</div>
								<div class="failure-message text-center position-absolute">
									<div class="icon display-1 mb-3">
										<font-awesome-icon
											class="text-danger bg-white"
											:icon="['fas', 'circle-xmark']"
											size="2xl"
										/>
									</div>
									<div class="message">
										{{ $t("request.error") }}
									</div>
								</div>
							</div>
						</Form>
						<h3>{{ $t("welcome_popup.no_wallet_yet") }}</h3>
						<p>{{ $t("welcome_popup.tutorial_text") }}</p>
						<video class="img-fluid mb-3" controls>
							<source src="/videos/wallet_creation.mp4" type="video/mp4" />
						</video>
						<h3>{{ $t("welcome_popup.safe_links") }}</h3>
						<p class="mb-4">
							<a class="btn btn-metamask" href="https://trustwallet.com/" target="_blank">Trust Wallet</a>
						</p>
						<h3>{{ $t("welcome_popup.need_help") }}</h3>
						<p>
							{{ $t("welcome_popup.help_text_part_1") }}
							<a class="text-dark text-decoration-underline" :href="`mailto:${helpEmailAddress}`">{{
								helpEmailAddress
							}}</a>
							{{ $t("welcome_popup.help_text_part_2") }}
						</p>
					</div>
				</div>
			</div>
		</div>
		<FeedbackDialog :showModal="showFeedback" :feedbackType="feedbackTypes.address"></FeedbackDialog>
	</AuthorizedLayout>
</template>

<script>
import { useUserStore } from "@/js/user.js";
import { feedbackTypes } from "@/js/constants.js";
import { Form, Field, ErrorMessage, configure } from "vee-validate";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Chart from "primevue/chart";
import { computed, ref, watch } from "vue";
import { right } from "@popperjs/core";
import bootstrap from "bootstrap/dist/js/bootstrap";
import * as yup from "yup";
import { i18n } from "@/main";
import { resetDialogState } from "@/js/common.js";
import FeedbackDialog from "@/components/dialogs/FeedbackDialog.vue";
import AddPackage from "@/components/dialogs/AddPackage.vue";
import Toast from "@/js/toast";

const schemaWallet = yup.object({
	wallet: yup
		.string()
		.required(() => i18n.global.t("validation.required"))
		.matches(/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,99}$/, () => i18n.global.t("validation.wallet_address"))
});

export default {
	components: {
		AddPackage,
		FeedbackDialog
	},
	data() {
		return {
			user: null,
			transactions: { items: null, size: null, loading: true },
			computingPower: 0,
			isReferMsgHidden: false,
			packageDelete: null,
			helpEmailAddress: "helpdesk@block-b.de",
			selectedCustomer: null,
			showFeedback: false,
			feedbackTypes
		};
	},
	methods: {
		async updateWallet(values, eventHandler) {
			let userStore = useUserStore();
			eventHandler.evt.target.classList.add("submitted");

			let walletData = {
				wallet_address: values["wallet"],
				customer_id: this.selectedCustomer.customer_id
			};

			userStore
				.updateWallet(walletData)
				.then(response => {
					if (response == 204) {
						eventHandler.evt.target.classList.add("success");
						userStore.isAuthenticated().then(response => {
							this.user = userStore.$state.user;
						});
					} else eventHandler.evt.target.classList.add("failure");
				})
				.catch(error => {
					console.log(error);
				});
		},
		async cancelPackage(values, eventHandler) {
			let userStore = useUserStore();
			eventHandler.evt.target.classList.add("submitted");
			let body = "ID: " + values["id"];

			if (values["other"]) body += "<br>Bemerkung: " + values["other"];

			let cancelData = {
				body: body,
				subject: "Order cancellation: " + values["id"]
			};

			userStore
				.sendFormSupport(cancelData)
				.then(response => {
					if (response == 204) {
						eventHandler.evt.target.classList.add("success");
					} else eventHandler.evt.target.classList.add("failure");

					setTimeout(() => {
						eventHandler.evt.target.classList.remove("submitted");
						eventHandler.evt.target.classList.remove("success");
						eventHandler.evt.target.classList.remove("failure");
					}, 4000);
				})
				.catch(error => {
					console.log(error);
				});
		},
		resetForm(event) {
			console.log(event);
		},
		onPageSelection(event) {
			let userStore = useUserStore();
			this.transactions["loading"] = true;
			userStore.getTransactions(event.page + 1, event.rows).then(transactions => {
				this.transactions = transactions;
			});
		},
		initializeData(user) {
			this.computingPower = 0;
			this.user = user.$state.user;

			user.getTransactions().then(transactions => {
				this.transactions = transactions;
			});

			user.$state.user.contracts.forEach(contract => {
				if (contract.days_left > 0) {
					this.computingPower += contract.tera_hashes;
				}
			});
		},
		showWalletFormIfNeeded() {
			if (!this.user.wallet_address && this.user.customers.length > 0) {
				var walletModal = new bootstrap.Modal(document.getElementById("enterWalletModal"));
				walletModal.show();
			}
		},
		limitBookIdLength(str) {
			return str.length > 13 ? str.slice(0, 11) + "..." : str
		},
		copyBookID(id) {
			navigator.clipboard
				.writeText(id)
				.then(() => {
					Toast.addToast(i18n.global.t("dashboard.transaction_id_copied"));
				})
				.catch(err => {
					console.error("Failed to copy book id:", err);
				});
		}
	},
	mounted: function () {
		this.showWalletFormIfNeeded();
	},
	beforeMount() {
		const user = useUserStore();
		this.initializeData(user);
		this.selectedCustomer = user.$state.user.selected_customer;

		const selectedCustomer = computed(() => user.getSelectedCustomer);

		watch(selectedCustomer, async (newVal, oldVal) => {
			this.selectedCustomer = newVal;
			await this.initializeData(user);
			await this.showWalletFormIfNeeded();
		});

		const profitabilities = this.user.profitability;
		while (profitabilities.length < 30) {
			profitabilities.unshift(0);
		}

		const difficultiesCalculated = this.user.difficulty.map(item => parseFloat((item / 1000000000000).toFixed(2)));
		while (difficultiesCalculated.length < 30) {
			difficultiesCalculated.unshift(0);
		}

		chartDataDifficulty.value = setChartData(
			difficultiesCalculated,
			profitabilities,
			"226, 113, 116",
			"24, 130, 54"
		);

		chartOptions.value = setChartOptions();
	}
};

const chartDataDifficulty = ref();
const chartDataProfitability = ref();
const chartOptions = ref();

const setChartData = ($difficultyValues, $profitabilityValues, $difficultyColor, $profitabilityColor) => {
	return {
		labels: [
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			""
		],
		yAxisID: "y",
		datasets: [
			{
				data: $difficultyValues,
				label: i18n.global.t("dashboard.difficulty"),
				backgroundColor: context => {
					var bgColor = [
						"rgba(" + $difficultyColor + ", 0.8)",
						"rgba(" + $difficultyColor + ", 0.4)",
						"rgba(" + $difficultyColor + ", 0.2)",
						"rgba(" + $difficultyColor + ", 0.0)",
						"rgba(" + $difficultyColor + ", 0.0)"
					];

					if (!context.chart.chartArea) {
						return;
					}
					const {
						ctx,
						data,
						chartArea: { top, bottom }
					} = context.chart;
					const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
					gradientBg.addColorStop(0, bgColor[0]);
					gradientBg.addColorStop(0.2, bgColor[1]);
					gradientBg.addColorStop(0.4, bgColor[2]);
					gradientBg.addColorStop(0.6, bgColor[3]);
					gradientBg.addColorStop(0.8, bgColor[4]);
					return gradientBg;
				},
				stepped: "after",
				fill: true,
				pointStyle: false,
				// borderColor: documentStyle.getPropertyValue('--bs-success'),
				borderColor: "rgb(" + $difficultyColor + ")",
				tension: 0
			},
			{
				data: $profitabilityValues,
				label: i18n.global.t("dashboard.profitability") + " (ct/TH)",
				yAxisID: "y1",
				backgroundColor: context => {
					var bgColor = [
						"rgba(" + $profitabilityColor + ", 0.8)",
						"rgba(" + $profitabilityColor + ", 0.4)",
						"rgba(" + $profitabilityColor + ", 0.2)",
						"rgba(" + $profitabilityColor + ", 0.0)",
						"rgba(" + $profitabilityColor + ", 0.0)"
					];

					if (!context.chart.chartArea) {
						return;
					}
					const {
						ctx,
						data,
						chartArea: { top, bottom }
					} = context.chart;
					const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
					gradientBg.addColorStop(0, bgColor[0]);
					gradientBg.addColorStop(0.2, bgColor[1]);
					gradientBg.addColorStop(0.4, bgColor[2]);
					gradientBg.addColorStop(0.6, bgColor[3]);
					gradientBg.addColorStop(0.8, bgColor[4]);
					return gradientBg;
				},
				stepped: "after",
				fill: true,
				pointStyle: false,
				borderColor: "rgb(" + $profitabilityColor + ")",
				tension: 0
			}
		]
	};
};

const setChartOptions = () => {
	return {
		plugins: {
			legend: {
				position: "bottom",
				align: "end",
				labels: {
					usePointStyle: true,
					generateLabels: chart => {
						const data = chart.data;
						return data.datasets.map((dataset, i) => ({
							text: dataset.label,
							fontColor: "gray",
							fillStyle: dataset.borderColor,
							strokeStyle: dataset.borderColor,
							lineCap: "butt",
							lineDash: [],
							lineDashOffset: 0,
							lineJoin: "miter",
							pointStyle: "circle",
							hidden: !chart.isDatasetVisible(i),
							datasetIndex: i
						}));
					}
				}
			},
			tooltip: {
				intersect: false,
				interaction: {
					mode: "index"
				}
			}
		},
		scales: {
			x: {
				display: false
			},
			y: {
				display: false
			},
			y1: {
				position: right,
				display: false
			}
		}
	};
};
</script>

<style lang="scss">
#collapseDetails .card-body {
	border-top: 5px dotted $secondary;
}

#enterWalletModal {
	backdrop-filter: blur(7px);

	#walletField {
		flex: auto;
	}

	.btn-metamask {
		background-color: #aad69b;
		border-color: #aad69b;
		color: #fff;
		font-weight: 400;
		--bs-btn-padding-y: 0.5rem;
	}
}
</style>
