<template>
	<router-link id="logo" to="/">
		<svg
			version="1.1"
			id="Camada_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 331.2 72.2"
			style="enable-background: new 0 0 331.2 72.2"
			xml:space="preserve"
		>
			<g>
				<path
					class="st0"
					d="M233.8,45.1l12.6-26.8h-17.2l-10,21.1h-4.7V0H198v71.4h16.5V50.5h4.7l9.9,20.9h17.2L233.8,45.1L233.8,45.1
			L233.8,45.1z"
				/>
				<path
					class="st0"
					d="M307.5,16.9L307.5,16.9c-7.7-0.1-13.6,3.5-16.5,8.3V0.1h-16.5v71.4h13.1l3.4-7.5c3,4.7,8.6,8.3,16.6,8.3
			c13.2,0,23.7-10.8,23.7-27.8S320.8,16.9,307.5,16.9z M313.2,54.8h-20.7V34.2h20.7V54.8z"
				/>
				<path
					class="st0"
					d="M33,16.9L33,16.9c-7.7-0.1-13.6,3.5-16.5,8.3V0.1H0v71.4h13.1l3.4-7.5c3,4.7,8.6,8.3,16.6,8.3
			c13.2,0,23.7-10.8,23.7-27.8S46.3,16.9,33,16.9L33,16.9z M38.8,54.8H18.1V34.2h20.6V54.8z"
				/>
				<path class="st0" d="M60.8,0.1h16.5v71.3H60.8V0.1z" />
				<path
					class="st0"
					d="M109.2,16.8c-15.7,0-28,10.6-28,27.7s12,27.7,27.8,27.7s28.1-10.6,28.1-27.7S124.9,16.8,109.2,16.8z
			M119.5,54.8H98.9V34.2h20.6V54.8z"
				/>
				<rect x="246.4" y="34.2" class="st0" width="20.7" height="20.6" />
				<path
					class="st0"
					d="M168.2,16.8c13.5,0,23.1,7.4,25.9,20.2h-17.5c-1.3-3.8-4-6.2-8.6-6.2s-9.9,4.6-9.9,13.6s4,13.6,9.9,13.6
			s7.1-2.2,8.6-6.2h17.5c-2.8,12.4-12.4,20.2-25.9,20.2s-27-10.6-27-27.7S152.4,16.8,168.2,16.8L168.2,16.8L168.2,16.8z"
				/>
			</g>
		</svg>
	</router-link>
</template>
