export const messages = {
    "contact_support": "Contact support",
    "current_language": "English",
    "confirm": "Confirm",
    "back": "Back",
    "account_not_setup_yet": "Your account is not fully set up yet, please come back later.",
    "details": "Details",

    "meta_data": {
        "profile": {
            "title": "Profile"
        },
        "login": {
            "title": "Login"
        },
        "dashboard": {
            "title": "Dashboard"
        },
        "reset_password": {
            "title": "Reset password"
        }
    },

    "login": {
        "email": "E-mail",
        "password": "Password",
        "login": "Login",
        "reset_password": "Reset password",
        "check": "Checking..",
        "dashboard": "Dashboard"
    },

    "new_rewards_popup": {
        "title": "New rewards received",
        "body_start": "Since your last login you have collected a staggering",
        "body_end": "!"
    },

    "cancel_popup": {
        "subline": "You want to leave us?",
        "headline": "Cancellation of your package",
        "bodytext":
            "In the following, you can tell us why you would like to cancel your Cloud savings plan. Your feedback will help us to respond even better to your needs in the future, thank you! And if you really do go through with this - take care and take care of yourself! We look forward to welcoming you back as a customer soon.",
        "textarea_reason": "What prompted you to resign?"
    },

    "add_plan_popup": {
        "subline": "Save more bitcoins with our packages",
        "headline": "Choose your package",
        "bodytext":
            "Get more cloud security power with new packages. Simply select the amount of packages you want and send it off - a colleague will then contact you promptly to implement your request!",
        "select_amount": "Select the amount of new packages",
        "textarea_additional": "Would you like to tell us anything else about this?",
        "year_with_vat": "year incl. VAT",
        "month_with_vat": "month incl. VAT",
        "day_with_vat": "day incl. VAT",
        "choose_package": "Choose your new package",
        "enter_quantity": "Enter quantity",
    },

    "info_savings_popup": {
        "headline": "Generated so far",
        "bodytext_1":
            "Important: All values shown here are estimates only and in no way constitute a guarantee. They are for illustrative purposes only and are not guaranteed.",
        "bodytext_2":
            'Here you can see at a glance how many Bitcoins or Bitcoin fractions (so-called "Satoshis") you have already successfully generated with your computing line.',
        "bodytext_3":
            'The second euro amount refers to the upcoming "halving", i.e. the halving of the reward that Bitcoin miners receive. This has a direct impact on the generation of Bitcoins and thus on the number of Satoshis you receive for your computing line - namely only around 50% as much as before on the halving date. Why is the euro amount under "Post-halving potential approx." then significantly higher than the value of the current Satoshis? Pay close attention! You can assume that the Bitcoin price will develop positively and upwards after the halving and that the price of your Satoshis will therefore rise on the market. Why? Put simply: Due to the scarcity of the inflow of new Bitcoins onto the market - namely only half as many - with at least the same (or even increasing) demand, it is very likely that the price of a Bitcoin will rise. But beware: this is no guarantee and Bitcoin is and remains a risky, volatile asset. There is a risk that the price will not develop as expected.'
    },

    "info_difficulty_popup": {
        "headline": "Profitability radar",
        "bodytext_1": "Important: All values shown here are for rough orientation only and are not guaranteed.",
        "bodytext_2":
            'The generation of Bitcoins - i.e. how many Bitcoins or Bitcoin fractions (so-called "Satoshis") you receive as a reward for your computing power - is significantly influenced by the network difficulty, the so-called "Difficulty". The value is regulated by the Bitcoin network itself and cannot be influenced by you or us. Of course, there are other factors, but this is one of the most important. In short: the higher the Difficulty increases, the fewer Satoshis you receive. The current level of difficulty is displayed as a red graph. You will also find a green graph. This shows the current profitability - i.e. how many Bitcoins are currently available as a reward for one Giga or Terahash of computing power, roughly speaking. Together, the values can give you a rough idea of the current profitability. But please note: The values are only intended as a rough guide and are not guaranteed.'
    },

    "info_computing_popup": {
        "headline": "Your computing power",
        "bodytext":
            "Here you can see how much computing power you are currently making available to the Bitcoin network. All your packages are taken into account and added together accordingly. To increase your computing power, purchase another package."
    },

    "reset_password": {
        "request_new": "Request a new",
        "password": "Password",
        "email": "E-mail",
        "send_reset_mail": "Send password reset mail",
        "new_password": "New password",
        "new_password_repeat": "Repeat new password",
        "set_new_password": "Set new password",
        "request_notice": "If a user with this e-mail address exists, an e-mail with further instructions will be sent.",
			"new_password_set": "Your new password has been set!",
			"check": "Checking..",
			"validations": {
				"email_required": "E-mail is a required field.",
				"use_valid_email": "Please use a valid e-mail.",
				"enter_new_password": "Please enter the new password.",
				"password_invalid": "Must contain at least 8 characters, 1 uppercase, 1 special Character, and 1 number.",
				"repeat_password": "Please repeat the password.",
				"password_must_match": "Passwords must match.",
				"link_expired": "The password reset link has expired. Please request a new one.",
				"unexpected_failure": "Unable to reset password. Please try again later."
			}
    },

    "request": {
        "error": "There was an error. Please try again later or contact support",
        "success_request": "Your request has been successfully sent",
        "success_data": "Your data has been successfully saved"
    },

    "validation": {
        "required": "This field is required",
        "wallet_address": "Please enter a valid wallet address",
        "credentials": "E-mail and/or password incorrect",
        "number": "Please enter a number",
        "number_above_0": "Please enter a number greater than 0",
        "number_less_9999": "Please enter a number less than 9999",
        "email": {
            "required": "E-mail is a required field",
            "valid": "Please use a valid e-mail"
        },
        "password": {
            "required": "Password is a required field",
            "match": "Passwords must match",
            "requirements": "Must contain at least 8 characters, 1 uppercase, 1 special Character, and 1 number",
            "wrong": "Wrong password"
        },
        "relogin_required_msg": "Your session has expired. Please log in again."
    },

    "welcome_popup": {
        "subline": "Nice to have you!",
        "headline": "Welcome to the swarm",
        "intro": "Before you can get started, we would like to ask you to enter your Bitcoin wallet address. We will send your bitcoins to this wallet (i.e. a digital wallet), which you will receive as a reward for your Bitcoin network security services.",
        "no_wallet_yet": "Don't have a wallet yet?",
        "tutorial_text":
            "Then watch the following video. We explain how to easily create a new wallet. Use our safe links below the video to access the resources you need:",
        "safe_links": "Safe Links",
        "need_help": "Need help?",
        "help_text_part_1": "Are you stuck at some point? Drop us a line to ",
        "help_text_part_2": " and we'll be glad to get you started",
        "start_now": "Start now!"
    },

    "navigation": {
        "logout": "Logout",
        "profile": "My profile",
        "selected_customer": "Selected Customer"
    },

    "dashboard": {
        "welcome_message": "Welcome back",
        "your_package": "Your package",
        "next_renewal": "Next renewal",
        "contract_number": "Contract number",
        "start_of_contract": "Start of contract",
        "contract_duration": "Contract duration",
        "days": "days",
        "daily_yield": "Daily yield",
        "monthly_yield": "Monthly yield",
        "total_income": "Total income to date",
        "cancel_package": "Cancel package",
        "increase_security": "Increase your security power with new packages",
        "generated_so_far": "Generated so far",
        "profitability": "Profitability",
        "difficulty": "Difficulty",
        "network_power": "Your network security power",
        "transactions": "Transactions",
        "empty_transactions_msg":
            "You don’t have transactions yet, Book a contract to receive hashpower for passive income!",
        "date": "Date",
        "contract_id": "Contract ID",
        "amount": "Amount",
        "type": "Type",
        "lets_go": "Let's go!",
        "transaction_id_copied": "Transaction ID was copied to your clipboard!"
    },

    "profile": {
        "my_profile": "My profile",
        "name": "Name",
        "email": "E-mail",
        "last_name": "Last name",
        "first_name": "First name",
        "wallet": "Wallet for withdrawals",
        "zip": "Postal code",
        "city": "City",
        "street_number": "Street, house number",
        "zip_city": "Postal code, city",
        "country": "Country",
        "change_password": "Change password",
        "edit_info": "Edit info"
    },

    "update_password_popup": {
        "title": "Change password",
        "current_password": "Current password",
        "new_password": "New password",
        "repeat_password": "Repeat new password"
    },

    "update_info_popup": {
        "title": "Edit info"
    },

    "affiliate": {
        "refer_a_friend": "Refer a friend and get free hashpower",
        "affiliate_popup": {
            "title": "Affiliate",
            "sub_title": "Share your link to friends and receive free hashpower",
            "your_code": "Your affiliate link:",
            "friend": "friend",
            "registered_count_notice_singular":
                "friend have registered already using your link. It's your turn to earn hashpower.",
            "registered_count_notice_plural":
                "friends have registered already using your link. It's your turn to earn hashpower.",
            "bronze": "Bronze",
            "silver": "Silver",
            "gold": "Gold",
            "user_advertise": "Refer a friend",
            "carousel": {
                "share_title": "Share link to friend",
                "share_description":
                    "Share the link to your friend so that your friend can register easily and you will get hashpower. ",
                "friends_title": "Friends uses your link",
                "friends_description": "Your friend has to register using your link.",
                "receive_title": "Receive hashpower",
                "receive_description":
                    "When your friend registered using your link and booked a mining plan, you will receive your hashpower!",
                "monthly_reset_title": "Monthly reset",
                "monthly_reset_description":
                    "Your affiliate hash power will be active starting the following month and end after a month. Keep growing your network in order to grow month after month!"
            },
            "your_potential_earnings": "Your Potential Earnings",
            "affiliate_count": "Affiliate Count",
            "bonus": "Bonus",
            "additional_bonus": "Additional Bonus",
            "total_bonus": "Total Bonus",
            "your_earning_streak": "Your Earning Streak",
            "affiliates_activated": "Affiliates Activated",
            "th_received": "Tera Hashes Received",
            "choose_month": "View Month",
            "customer": "Customer",
            "month": "Month",
            "current_bonus": "Current Bonus",
            "everything": "Everything",
            "no_data_found": "No data found",
            "copied": "Copied"
        }
    },
    "videos_popup": {
        "video_not_available": "Video not available",
        "title": "Learning content",
        "sub_title": "Familiarize yourself with the basic information about Bitcoin and Bitcoin mining. We expand the content continuously."
    },
    "support_modal": {
        "title": "Support Request",
        "heading": "We are here to help you",
        "description": "What is your request about? Please give us as much information as possible. A team member will get in touch with you within the next 24 hours to help you with your request (usually even faster). Further communication will take place by e-mail.",
        "select_category": "Select category",
        "payment": "Payment",
        "technical": "Technical",
        "general": "General",
        "describe_request": "Please describe your request",
        "send": "Send",
        "request_sent": "Your request has been successfully sent.",
        "request_error": "There was an error. Please try again later or contact us per mail."
    },
    "feedback": {
        "video_message": "Congrats! You successfully gathered your first bitcoin knowledge! How would you rate our videos?",
        "login_message": "Thanks for liking our product. How would you rate our dashboard?",
        "address_message": "Congrats! You successfully mastered your first step into the world of Bitcoin! How would you rate your experience so far?",
        "how_can_we_do_better": "Please share your thoughts on your rating: How can we do better?",
        "send": "Send",
        "feedback_sent": "Feedback sent! Thank you for taking the time to send us your feedback.",
        "unable_to_send": "Unable to send feedback. Please try again later.",
        "full_rating": {
            "first_line": "Wow, thank you!",
            "second_line": "Would you mind to share your positive impression on Trustpilot?",
            "third_line": "You would help us a lot:",
            "rate_on_trustpilot": "Rate on Trustpilot"
        }
    }
}
