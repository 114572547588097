<template class="z-index-3">
	<button
		data-bs-toggle="modal"
		data-bs-target="#videosModal"
		class="position-fixed btn btn-primary mw-0 bottom-0 end-0 rounded-circle me-3 mb-3 p-3 z-3"
	>
		<font-awesome-icon :icon="['fas', 'graduation-cap']" size="2xl" />
	</button>
	<div
		class="modal fade"
		id="videosModal"
		tabindex="-1"
		data-bs-backdrop="true"
		aria-labelledby="videosModalLabel"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable video-modal-container">
			<div class="modal-content">
				<button
					type="button"
					class="btn-close btn-close-white"
					data-bs-dismiss="modal"
					aria-label="Close"
				></button>

				<div class="modal-body bg-dark text-white position-relative">
					<h1 class="mb-3">{{ $t("videos_popup.title") }}</h1>
					<p class="mb-0 text-secondary">{{ $t("videos_popup.sub_title") }}</p>

					<ul class="list-group list-group-flush mt-5">
						<li
							@click="playVideo(index, vid)"
							class="list-group-item bg-dark ps-2 border-secondary border-opacity-25 cursor-pointer text-secondary hover:text-white"
							v-for="(vid, index) in videos"
							:key="vid.id"
							:class="{ viewed: vid.viewed }"
						>
							<span class="pe-3">
								<font-awesome-icon :icon="['far', 'circle-play']" size="2xl" />
							</span>
							<span :class="`fw-bold`">
								<span v-if="$i18n.locale === 'en'">{{ getTitle(vid.titles, "en") }}</span>
								<span v-if="$i18n.locale === 'de'">{{ getTitle(vid.titles, "de") }}</span>
							</span>
						</li>
					</ul>
					<div
						v-if="videos.length > 0"
						class="position-absolute bottom-0 end-0 video-modal-bg d-sm-block d-none"
					></div>
				</div>
			</div>
		</div>
	</div>

	<!-- Video Player -->
	<div
		v-if="isVideoVisible"
		class="video-player-container w-100 bg-dark bg-opacity-75 position-fixed top-0 bottom-0 m-auto"
	>
		<button @click="hideVideo()" type="button" class="btn-close btn-close-white float-end m-4"></button>
		<div class="clearfix"></div>
		<p class="text-center fw-bold text-white mb-2">{{ playing.title }}</p>
		<div class="text-center border-top border-primary title-border m-auto mb-2"></div>

		<!-- Video -->
		<div
			:class="`${isVideoAvailable ? 'h-sm-75' : 'bg-dark rounded rounded-3 h-sm-50'} mt-3 w-sm-50 position-relative m-auto d-flex text-white align-items-center justify-content-center`"
			class=""
		>
			<div v-if="!isVideoCheckDone" class="loading text-center position-absolute">
				<div class="icon display-1 mb-3">
					<font-awesome-icon
						class="bg-none text-white"
						:icon="['fas', 'circle-notch']"
						size="2xl"
						spin
						spin-reverse
					/>
				</div>
			</div>
			<VideoPlayer
				v-if="isVideoAvailable && isVideoCheckDone"
				:url="playing.url"
				:isExternal="isExternal"
			></VideoPlayer>
			<p v-if="!isVideoAvailable && isVideoCheckDone" class="text-white">Video not available</p>
			<div class="controls position-absolute w-100">
				<font-awesome-icon
					v-bind:class="{ 'text-white cursor-pointer': playing.index > 0 }"
					class="position-absolute prev text-secondary"
					ref="prev"
					:icon="['fas', 'arrow-left']"
					size="2xl"
					@click="playNthVideo(playing.index - 1, playing)"
				/>
				<font-awesome-icon
					v-bind:class="{ 'text-white cursor-pointer': playing.index < videos.length - 1 }"
					class="position-absolute next text-secondary"
					ref="next"
					:icon="['fas', 'arrow-right']"
					size="2xl"
					@click="playNthVideo(playing.index + 1, playing)"
				/>
			</div>
		</div>
	</div>
	<FeedbackDialog :showModal="showFeedbackModal" :feedbackType="feedbackTypes.video"></FeedbackDialog>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";

import FeedbackDialog from "@/components/dialogs/FeedbackDialog.vue";

import { useUserStore } from "@/js/user.js";
import { feedbackTypes } from "@/js/constants.js";
import axios from "axios";

export default {
	components: {
		VideoPlayer,
		FeedbackDialog
	},
	props: {
		userFeedbacks: {
			required: true
		}
	},
	data() {
		return {
			user: null,
			userId: null,
			isVideoVisible: false,
			playing: {
				index: 0,
				title: "",
				url: "",
				viewed: false
			},
			videos: [],
			isVideoCheckDone: false,
			isVideoAvailable: false,
			isExternal: false,
			showFeedbackModal: false,
			feedbackTypes
		};
	},
	methods: {
		isValidUrl(urlString) {
			return urlString.startsWith("http");
		},
		hideVideo() {
			this.isVideoVisible = false;

			if (this.isVideoAvailable) {
				this.setAsViewed(this.playing.id);
			}
		},
		async useVideoPath(path) {
			this.isVideoCheckDone = false;
			let videoPath = `/videos/${path}`;

			if (this.isValidUrl(path)) {
				this.isExternal = true;
				this.isVideoAvailable = true;
				this.isVideoCheckDone = true;
				return path;
			}

			this.isExternal = false;

			try {
				const response = await axios.get(`/videos/${path}`);
				if (response.status === 200) {
					this.fileExists = true;
				} else {
					this.fileExists = false;
				}
			} catch {
				this.isVideoCheckDone = true;
				this.isVideoAvailable = false;
				return "";
			}

			this.isVideoCheckDone = await true;
			this.isVideoAvailable = await true;
			return videoPath;
		},
		async playVideo(index, vid) {
			this.isVideoVisible = true;

			this.playing = {
				id: vid.id,
				index,
				title: this.$i18n.locale === "en" ? vid.title_en : vid.title_de,
				url: await this.useVideoPath(vid.url),
				viewed: vid.viewed
			};
		},
		async playNthVideo(nth, prevVid) {
			if (this.isVideoAvailable) {
				this.setAsViewed(prevVid.id);
			}

			if (nth >= 0 && nth < this.videos.length) {
				const vid = this.videos[nth];
				this.playing = {
					id: vid.id,
					index: nth,
					title: this.$i18n.locale === "en" ? vid.title_en : vid.title_de,
					url: await this.useVideoPath(vid.url),
					viewed: vid.viewed
				};
			}
		},
		showFeedbackIfNeeded() {
			if (
				Array.isArray(this.userFeedbacks) &&
				!this.userFeedbacks.find(val => val.feedback_type === this.feedbackTypes.video)
			) {
				this.showFeedbackModal = true;
			}
		},
		getContentVideos() {
			this.user.getContentVideos().then(videos => {
				if (videos && videos.length > 0) {
					this.videos = videos;

					if (videos.filter(vid => vid.viewed).length === videos.length) {
						this.showFeedbackIfNeeded();
					}
				}
			});
		},
		setAsViewed(id) {
			this.user.setContentVideoAsViewed(id).then(_ => this.getContentVideos());
		},
		getTitle(titles, key) {
			let title = "";

			try {
				if (titles) {
					const newTitle = titles.find(val => val.language === key);
					title = newTitle.title;
				}
			} catch (error) {
				console.log(`Failed to load ${key} title.`, error);
			}

			return title;
		}
	},
	beforeMount() {
		this.user = useUserStore();
		this.getContentVideos();
	},
	mounted() {
		this.userId = this.user.$state.user.user_id;
	}
};
</script>
