import { defineStore } from "pinia";
import axios from "axios";
import { router } from "@/js/router";
import Toast from "./toast";
import { confetti } from "@tsparticles/confetti";
import { i18n } from "@/main";

const api = axios.create({
	baseURL: process.env.VUE_APP_BLOCKB_API_URL
});

export const useUserStore = defineStore({
	id: "user",
	state: () => ({
		user: null,
		token: JSON.parse(localStorage.getItem("token")),
		rewards_message_shown: JSON.parse(localStorage.getItem("rewards_message_shown")),
		returnUrl: null,
		selectedCustomer: null
	}),
	actions: {
		async login(username, password) {
			try {
				const queryParams = new URLSearchParams(window.location.search);

				let response = await api.post(
					"/v1/auth/login",
					{
						email: username,
						password: password
					},
					{
						withCredentials: true
					}
				);
				this.user = response.data;
				this.token = response.data.access_token;
				localStorage.setItem("token", JSON.stringify(response.data.access_token));
				if (response.status == 200) {
					// Check if the "path" parameter exists
					if (queryParams.has("path")) {
						router.push(queryParams.get("path"));
					} else {
						router.push("/");
					}
				}
				return response.status;
			} catch (error) {
				return error.request.status;
			}
		},
		async requestReset(email) {
			try {
				let response = await api.post("/v1/auth/reset", {
					email: email
				});
				return response.status;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.requestReset(email);
				}

				return error.request.status;
			}
		},
		async validateToken(resetId) {
			try {
				let response = await api.get("/v1/auth/reset/confirm", {
					params: { reset_id: resetId }
				});
				return response.status;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.validateToken(resetId);
				}

				return "error";
			}
		},
		async newPassword(token, password) {
			try {
				let response = await api.post("/v1/auth/reset/confirm/" + token, {
					new_password: password
				});
				if (response.status == 204) {
					router.push("/login");
					Toast.addToast(i18n.global.t("reset_password.new_password_set"));
				}

				return response.status;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.newPassword(token, password);
				}

				return error.request.status;
			}
		},
		async updateData(customerData, walletData) {
			try {
				let responseCustomer = await api.patch("/v1/customers/personal", customerData, {
					headers: { Authorization: `Bearer ${this.token}` },
					params: customerData
				});
				let responseWallet = await api.patch("/v1/wallets/personal", walletData, {
					headers: { Authorization: `Bearer ${this.token}` },
					params: walletData
				});
				return responseCustomer.status && responseWallet.status;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.updateData(customerData, walletData);
				}

				return error.request.status;
			}
		},
		async updateWallet(walletData) {
			try {
				let responseWallet = await api.patch("/v1/wallets/personal", walletData, {
					headers: { Authorization: `Bearer ${this.token}` },
					params: walletData
				});
				return responseWallet.status;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.updateWallet(walletData);
				}

				return error.request.status;
			}
		},
		async updatePassword(passwordData) {
			try {
				let response = await api.patch("/v1/users/personal/password", passwordData, {
					headers: { Authorization: `Bearer ${this.token}` },
					params: passwordData
				});
				return response.status;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.updatePassword(passwordData);
				}

				return false;
			}
		},
		async sendFormSupport(supportData) {
			try {
				let response = await api.post("/v1/forms/support", supportData, {
					timeout: 5000,
					headers: { Authorization: `Bearer ${this.token}` }
				});
				return response.status;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.sendFormSupport(supportData);
				}

				return error.request.status;
			}
		},
		async getTransactions(page = 1, size = 10) {
			try {
				let response = await api.get("/v1/transactions/", {
					timeout: 10000,
					headers: { Authorization: `Bearer ${this.token}` },
					params: { page: page, pageSize: size, customer_id: this.getSelectedCustomerIdFromStorage() }
				});
				return response.data;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.getTransactions(page, size);
				}

				return error.request.status;
			}
		},
		async getSessionData(customerId = "") {
			try {
				customerId = this.getSelectedCustomerIdFromStorage(customerId);

				let userResponse = await api.get(`/v1/session/data${customerId ? "?customer_id=" + customerId : ""}`, {
					timeout: 10000,
					headers: { Authorization: `Bearer ${this.token}` }
				});

				if (userResponse.data) {
					this.user = await userResponse.data;
					await this.setSelectedCustomer(userResponse.data.selected_customer);
				}

				if (!this.rewards_message_shown && userResponse.data.earnings_since_last_login > 0) {
					setTimeout(() => {
						confetti({
							particleCount: 500,
							spread: 200,
							origin: { y: 1, x: 1 },
							angle: 90,
							decay: 0.8
						});
					}, 300);

					Toast.addToast(
						`
							<div class="border-0 d-block toast-header bg-white text-center">
								<div class="fw-bold text-dark text-uppercase">` +
							i18n.global.t("new_rewards_popup.title") +
							`</div>
								<button type="button" class="btn-close position-absolute end-0 top-0 mt-1 me-1 p-2" data-bs-dismiss="toast" aria-label="Close" style=" z-index: 99;"></button>
							</div>
							<div class="bg-white border-0 toast-body pt-0 text-center">
								<svg width="50" class="mb-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style="fill: #fed636;">
									<path d="M400 0H176c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8H24C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9H192c-17.7 0-32 14.3-32 32s14.3 32 32 32H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H357.9C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24H446.4c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6h84.4c-5.1 66.3-31.1 111.2-63 142.3z"></path>
								</svg>
								<p class="text-dark m-0">` +
							i18n.global.t("new_rewards_popup.body_start") +
							` <b>${userResponse.data.earnings_since_last_login.toFixed(5)} BTC</b>` +
							i18n.global.t("new_rewards_popup.body_end") +
							`</p>
							</div>
						`
					);
				}

				this.rewards_message_shown = 1;
				localStorage.setItem("rewards_message_shown", JSON.stringify(1));

				return true;
			} catch (error) {
				const status = error && error.request && error.request.status ? error.request.status : -1; 
				
				if (status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.getSessionData();
				}

				return status;
			}
		},
		async isAuthenticated() {
			if (this.token != null) {
				try {
					const response = await api.post(
						"/v1/session/",
						{},
						{
							timeout: 5000,
							headers: { Authorization: `Bearer ${this.token}` }
						}
					);

					if (response && response.status == 200) {
						// Make request to GET session/ and set this.user
						return this.getSessionData();
					} else {
						this.rewards_message_shown = null;
						localStorage.removeItem("rewards_message_shown");
						return false;
					}
				} catch (error) {
					if (error.request.status === 401) {
						const isNewTokenGenerated = await this.refreshToken();

						// Rerun method if new token is generated
						return isNewTokenGenerated && this.isAuthenticated();
					}

					return false;
				}
			} else {
				return false;
			}
		},
		async getAffiliateCustomers(yearMonth = "") {
			try {
				let response = await api.get(
					`/v1/users/affiliates/customers?year_month=${yearMonth}&customer_id=${this.getSelectedCustomerIdFromStorage()}`,
					{
						timeout: 10000,
						headers: { Authorization: `Bearer ${this.token}` }
					}
				);
				return response.data;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.getAffiliateCustomers(yearMonth);
				}

				return error.request.status;
			}
		},
		async getAffiliateCurrentBonus() {
			try {
				let response = await api.get(
					`/v1/users/affiliates/boosts/current?customer_id=${this.getSelectedCustomerIdFromStorage()}`,
					{
						timeout: 10000,
						headers: { Authorization: `Bearer ${this.token}` }
					}
				);
				return response.data;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.getAffiliateCurrentBonus();
				}

				return error.request.status;
			}
		},
		async getContentVideos() {
			try {
				let response = await api.get("/v1/content_videos/", {
					timeout: 10000,
					headers: { Authorization: `Bearer ${this.token}` }
				});
				return response.data;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.getContentVideos();
				}

				return error.request.status;
			}
		},
		async getFeedbacksByUserID() {
			try {
				let response = await api.get("/v1/feedbacks/user", {
					timeout: 10000,
					headers: { Authorization: `Bearer ${this.token}` }
				});
				return response.data;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.getFeedbacksByUserID();
				}

				return error.request.status;
			}
		},
		async getFeedbacksByCustomerID() {
			try {
				let response = await api.get("/v1/feedbacks/user", {
					timeout: 10000,
					headers: { Authorization: `Bearer ${this.token}` }
				});
				return response.data;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.getFeedbacksByCustomerID();
				}

				return error.request.status;
			}
		},
		async saveFeedback(data) {
			try {
				let response = await api.post("/v1/feedbacks/", data, {
					timeout: 10000,
					headers: { Authorization: `Bearer ${this.token}` }
				});
				return response.status;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.saveFeedback();
				}

				return error.request.status;
			}
		},
		async setContentVideoAsViewed(id) {
			try {
				let response = await api.patch(`/v1/content_videos/viewed/${id}`, null, {
					timeout: 10000,
					headers: { Authorization: `Bearer ${this.token}` }
				});
				return response.status;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.setContentVideoAsViewed(id);
				}

				return error.request.status;
			}
		},
		async getPlans() {
			try {
				let response = await api.get("/v1/plans/active", {
					timeout: 10000,
					headers: { Authorization: `Bearer ${this.token}` }
				});
				return response.data;
			} catch (error) {
				if (error.request.status === 401) {
					const isNewTokenGenerated = await this.refreshToken();

					// Rerun method if new token is generated
					return isNewTokenGenerated && this.getPlans();
				}

				return error.request.status;
			}
		},
		async refreshToken() {
			return new Promise(resolve => {
				api.post("/v1/auth/refresh_token", null, {
					timeout: 10000,
					headers: { Authorization: `Bearer ${this.token}` },
					withCredentials: true
				})
					.then(response => {
						localStorage.setItem("token", JSON.stringify(response.data.access_token));
						this.token = response.data.access_token;
						return resolve(true);
					})
					.catch(_ => {
						const currentRoute = window.location.pathname;
						// Avoid redirection when on login or reset password page
						if (
							!currentRoute.includes("login") &&
							!currentRoute.includes("reset-password") &&
							currentRoute != "/"
						) {
							window.location.href = `/login?path=${currentRoute}`;
						}

						return resolve(false);
					});
			});
		},
		logout() {
			this.user = null;
			this.token = null;
			this.rewards_message_shown = null;
			localStorage.removeItem("token");
			localStorage.removeItem("rewards_message_shown");
			router.push("/login");
		},
		setSelectedCustomer(customer) {
			this.selectedCustomer = customer;
			localStorage.setItem("selected_customer_id", customer.customer_id);
		},
		getSelectedCustomerIdFromStorage(id) {
			let customerId = 0;

			if (id > 0) {
				customerId = id;
			} else if (
				localStorage.getItem("selected_customer_id") &&
				localStorage.getItem("selected_customer_id") > 0
			) {
				customerId = localStorage.getItem("selected_customer_id");
			} else if (this.user && this.user.selected_customer && this.user.selected_customer.customer_id > 0) {
				customerId = this.user.selected_customer.customer_id;
			}

			return customerId;
		}
	},
	getters: {
		getSelectedCustomer: state => state.selectedCustomer
	}
});
