import bootstrap from "bootstrap/dist/js/bootstrap";

export default class Toast {
	static addToast(body, cssClass = "") {
		var toasts = document.getElementById("toasts");
		var toast = document.createElement("template");
		toast.innerHTML = `<div class="p-4 toast position-relative align-items-center text-dark border-0 ${cssClass}" role="alert" aria-live="assertive" aria-atomic="true">
            ${body}
        </div>`;
		toast = toast.content.firstChild;
		toasts.append(toast);
		toast = new bootstrap.Toast(toast);
		toast.show();
	}
}
