<script setup>
import GuestLayout from "@/layouts/GuestLayout.vue";
</script>

<template>
	<GuestLayout>
		<div class="display-5 text-secondary mb-2">{{ $t("login.login") }}</div>
		<span class="display-1 text-uppercase text-white">{{ $t("login.dashboard") }}</span>

		<Form @submit="login" :validation-schema="schema" class="mt-5 pt-lg-3">
			<div class="mb-3">
				<label for="username" class="form-label text-white">{{ $t("login.email") }}</label>
				<div class="login-input position-relative">
					<Field
						name="email"
						type="email"
						class="form-control bg-info text-secondary border-0 border-bottom border-black border-3 rounded-0 py-3"
					/>
				</div>
				<ErrorMessage name="email" class="text-danger" />
			</div>
			<div class="mb-3">
				<label for="password" class="form-label text-white">{{ $t("login.password") }}</label>
				<div class="login-input position-relative">
					<Field
						name="password"
						:type="showPassword ? 'text' : 'password'"
						class="form-control bg-info text-secondary border-0 border-bottom border-black border-3 rounded-0 py-3"
					/>
					<div class="position-absolute end-0 top-0 bottom-0 px-2 d-flex align-items-center">
						<div @click="showPassword = !showPassword" class="cursor-pointer">
							<font-awesome-icon
								class="text-secondary cursor-pointer me-2"
								:icon="['fas', showPassword ? 'eye-slash' : 'eye']"
							/>
						</div>
					</div>
				</div>
				<ErrorMessage name="password" class="text-danger" />
			</div>
			<div
				id="loginError"
				class="text-danger d-inline-block mt-1"
				:class="loginError != '' ? 'd-block' : 'd-none'"
			>
				{{ loginError != "" ? loginError : "&nbsp;" }}
			</div>
			<div class="mt-5 d-flex justify-content-between align-items-center">
				<button v-if="validating" class="btn btn-primary" type="button" disabled>
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					{{ $t("login.check") }}
				</button>
				<button v-else class="btn btn-primary" type="submit">
					{{ $t("login.login") }}
				</button>
				<router-link to="/reset-password" class="text-secondary text-decoration-none fw-semibold ms-5">{{
					$t("login.reset_password")
				}}</router-link>
			</div>
		</Form>
	</GuestLayout>
</template>

<script>
import { useUserStore } from "@/js/user.js";
import { ref } from "vue";
import { Form, Field, ErrorMessage, configure } from "vee-validate";
import * as yup from "yup";

configure({
	validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
	validateOnChange: false, // controls if `change` events should trigger validation with `handleChange` handler
	validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
	validateOnModelUpdate: false // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

export default {
	data() {
		return {
			email: "",
			password: "",
			validating: ref(false),
			loginError: "",
			showPassword: ref(false),
			schema: ref(null),
			queryParams: new URLSearchParams(window.location.search)
		};
	},
	beforeMount() {
		this.schema = yup.object({
			email: yup.string().required(this.$t("validation.email.required")).email(this.$t("validation.email.valid")),
			password: yup.string().required(this.$t("validation.password.required"))
		});

		if (this.queryParams.has("path") && this.queryParams.get("path") !== "/reset-password") {
			this.loginError = this.$t("validation.relogin_required_msg");
		}
	},
	methods: {
		async login(values) {
			if (values.email == "" || values.password == "") return false;
			this.validating = true;
			const userStore = useUserStore();
			let state = await userStore.login(values.email, values.password);

			if (state == 401) {
				this.loginError = this.$t("validation.credentials");
			}
			this.validating = false;
		}
	}
};
</script>

<style lang="scss">
.login-input {
	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 3px;
		background-color: $primary;
		width: 0;
		transition: 0.4s ease;
	}

	&:focus-within:after {
		width: 100%;
	}

	.form-control {
		outline: 0 !important;
		box-shadow: none !important;
	}
}
</style>
