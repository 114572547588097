<style lang="scss" scoped>
#languageSelector {
	position: relative;
	display: inline-block;

	button {
		background: transparent;
		border: 0;
		padding: 0;
		color: white;
		cursor: pointer;
		white-space: nowrap;
	}

	#languageOptions {
		text-align: left;
		position: absolute;
		top: 100%;
		left: -1rem;
		right: -1rem;
		margin-top: 0.25rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-left: 1rem;

		button {
			padding: 0.1rem 0;
		}
	}
}
</style>

<template>
	<div id="languageSelector" class="position-relative">
		<button @click="responsiveSettingsLanguage = !responsiveSettingsLanguage" id="selectedLanguage">
			<img width="21" :src="require('/src/assets/lang/' + this.$i18n.locale + '.png')" />
			{{ $t("current_language") }}
			<font-awesome-icon :icon="['fas', 'caret-down']" class="button-caret" />
		</button>
		<div id="languageOptions" class="text-white bg-info" v-if="responsiveSettingsLanguage">
			<button v-if="this.$i18n.locale != 'de'" @click="setLocale('de')">
				<img width="21" :src="require('/src/assets/lang/de.png')" />
				Deutsch
			</button>
			<button v-if="this.$i18n.locale != 'en'" @click="setLocale('en')">
				<img width="21" :src="require('/src/assets/lang/en.png')" />
				English
			</button>
		</div>
	</div>
</template>

<script>
import { ref } from "vue";
export default {
	data() {
		return {
			responsiveSettingsLanguage: ref(false)
		};
	},
	methods: {
		setLocale(locale) {
			this.responsiveSettingsLanguage = false;
			localStorage.setItem("language", locale);
			location.reload();
			// this.$i18n.locale = locale;
		}
	}
};
</script>
