<template>
	<div
		class="modal fade"
		id="affiliateModal"
		tabindex="-1"
		data-bs-backdrop="true"
		aria-labelledby="affiliateModalLabel"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content affiliate-container">
				<button
					type="button"
					class="btn-close btn-close-white"
					data-bs-dismiss="modal"
					aria-label="Close"
				></button>

				<div class="modal-body bg-dark text-white">
					<h1>{{ $t("affiliate.affiliate_popup.title") }}</h1>
					<p class="mb-0">{{ $t("affiliate.affiliate_popup.sub_title") }}</p>

					<label class="mt-4 text-primary fw-bold"
						>{{ $t("affiliate.affiliate_popup.current_bonus") }}: {{ currentBonus }} TH/s</label
					>

					<p class="mt-4">{{ $t("affiliate.affiliate_popup.your_code") }}</p>
					<div class="p-4 bg-light rounded">
						<span>{{ affiliateUrl }}{{ customerCode }}</span>
						<span v-if="isCodeCopied" class="float-end">
							<font-awesome-icon :icon="['fas', 'check']" />
							{{ $t("affiliate.affiliate_popup.copied") }}!
						</span>
						<img
							v-if="!isCodeCopied"
							@click="copyCode"
							class="cursor-pointer float-end"
							width="24"
							:src="require('/src/assets/files.png')"
						/>
					</div>

					<p v-if="friendsRegisteredCount > 0" class="text-primary mt-3">
						{{ friendsRegisteredCount }} 
						<span v-if="friendsRegisteredCount === 1">{{ $t("affiliate.affiliate_popup.registered_count_notice_singular") }}</span>
						<span v-if="friendsRegisteredCount > 1">{{ $t("affiliate.affiliate_popup.registered_count_notice_plural") }}</span>
					</p>

					<div
						id="carouselExampleIndicators"
						class="carousel slide my-3 py-3"
						data-bs-ride="carousel"
						data-bs-interval="4000"
						data-keyboard="true"
					>
						<div class="carousel-indicators pb-4">
							<button
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide-to="0"
								class="active"
								aria-current="true"
								aria-label="Slide 1"
							></button>
							<button
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide-to="1"
								aria-label="Slide 2"
							></button>
							<button
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide-to="2"
								aria-label="Slide 3"
							></button>
							<button
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide-to="3"
								aria-label="Slide 4"
							></button>
						</div>
						<div class="carousel-inner bg-light p-4 p-sm-5">
							<div class="carousel-item active">
								<div class="row align-items-center">
									<div class="col">
										<img
											:src="require('/src/assets/affiliate/share.svg')"
											class="d-block w-100"
											alt="ReceiveHashPowerImage"
										/>
									</div>
									<div class="col align-middle-container text-sm-start">
										<div>
											<h2>{{ $t("affiliate.affiliate_popup.carousel.share_title") }}</h2>
											<p class="mt-3">
												{{ $t("affiliate.affiliate_popup.carousel.share_description") }}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="row align-items-center">
									<div class="col">
										<img
											:src="require('/src/assets/affiliate/friends.svg')"
											class="d-block w-100"
											alt="ReceiveHashPowerImage"
										/>
									</div>
									<div class="col align-middle-container text-sm-start">
										<div>
											<h2>{{ $t("affiliate.affiliate_popup.carousel.friends_title") }}</h2>
											<p class="mt-3">
												{{ $t("affiliate.affiliate_popup.carousel.friends_description") }}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="row align-items-center">
									<div class="col">
										<img
											:src="require('/src/assets/affiliate/receive.svg')"
											class="d-block w-100"
											alt="ReceiveHashPowerImage"
										/>
									</div>
									<div class="col align-middle-container text-sm-start">
										<div>
											<h2>{{ $t("affiliate.affiliate_popup.carousel.receive_title") }}</h2>
											<p class="mt-3">
												{{ $t("affiliate.affiliate_popup.carousel.receive_description") }}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="row align-items-center">
									<div class="col">
										<img
											:src="require('/src/assets/affiliate/monthly_reset.svg')"
											class="d-block w-100"
											alt="MonthlyResetImage"
										/>
									</div>
									<div class="col align-middle-container text-sm-start">
										<div>
											<h2>{{ $t("affiliate.affiliate_popup.carousel.monthly_reset_title") }}</h2>
											<p class="mt-3">
												{{ $t("affiliate.affiliate_popup.carousel.monthly_reset_description") }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<h2 class="mb-4">{{ $t("affiliate.affiliate_popup.your_potential_earnings") }}</h2>

					<div class="container text-center my-3">
						<div class="row">
							<div class="float-end p-0">
								<div class="container">
									<div class="row mb-2">
										<div class="affiliate-gap p-0">
											<div class="bg-light p-3 py-4 rounded w-50">
												<h3>{{ $t("affiliate.affiliate_popup.affiliate_count") }}</h3>
											</div>
											<div class="bg-light p-3 py-4 rounded w-50">
												<h3>{{ $t("affiliate.affiliate_popup.additional_bonus") }}</h3>
											</div>
											<div class="bg-light p-3 py-4 rounded w-50">
												<h3>{{ $t("affiliate.affiliate_popup.total_bonus") }}</h3>
											</div>
										</div>
									</div>
									<div class="clearfix"></div>
									<div
										class="row mb-2"
										v-for="row in potentialEarnings"
										:key="row.affiliateCount"
									>
										<div class="affiliate-gap p-0">
											<div class="bg-light p-3 py-4 rounded w-50 d-flex align-items-center justify-content-center">
												{{ row.affiliateCount }}
											</div>
											<div class="bg-light p-3 py-4 rounded w-50 d-flex align-items-center justify-content-center">
												{{ row.additionalBonus }}
											</div>
											<div class="bg-light p-3 py-4 rounded w-50 d-flex align-items-center justify-content-center">
												{{ row.totalBonus }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<h2 class="mb-4 mt-4">{{ $t("affiliate.affiliate_popup.your_earning_streak") }}</h2>

					<div class="container text-center">
						<div class="row text-start mb-3">
							<div class="col-sm-6 p-0">
								<div>
									<label>{{ $t("affiliate.affiliate_popup.affiliates_activated") }}: </label>
									{{ affiliateCustomers.length }}
								</div>
								<div>
									<label>{{ $t("affiliate.affiliate_popup.th_received") }}: </label>
									{{ affiliateCustomers.reduce((acc, obj) => acc + obj.earned_th, 0) }}
								</div>
							</div>
							<div class="col-sm-6 text-end pe-0 choose-month-button pt-3 pt-sm-0 ps-0">
								<div class="btn bg-light fw-normal text-white float-end m-w-100" @click="showCalender">
									{{
										selectedYearMonth
											? selectedYearMonth
											: $t("affiliate.affiliate_popup.choose_month")
									}}
									<font-awesome-icon :icon="['fas', 'angle-down']" class="button-caret" />
								</div>
								<input type="text" id="yearMonth" ref="yearMonth" />
							</div>
						</div>
					</div>
					<div class="bonus-container">
						<div class="container text-center">
							<div class="row mb-2">
								<div class="affiliate-gap d-sm-flex p-0">
									<div class="bg-light p-3 py-4 rounded col">
										<h3>{{ $t("affiliate.affiliate_popup.customer") }}</h3>
									</div>
									<div class="bg-light p-3 py-4 rounded col">
										<h3>{{ $t("affiliate.affiliate_popup.month") }}</h3>
									</div>
									<div class="bg-light p-3 py-4 rounded col">
										<h3>{{ $t("affiliate.affiliate_popup.bonus") }}</h3>
									</div>
								</div>
							</div>
							<div class="row mb-2" v-for="row in affiliateCustomers" :key="row.customer_id">
								<div class="affiliate-gap d-sm-flex p-0">
									<div class="bg-light p-3 py-4 rounded col">
										{{ row.name }}
									</div>
									<div class="bg-light p-3 py-4 rounded col">
										{{ row.month_year }}
									</div>
									<div class="bg-light p-3 py-4 rounded col">{{ row.earned_th }} TH/s</div>
								</div>
							</div>
							<div class="row" v-if="affiliateCustomers.length < 1">
								<div class="bg-light p-3 py-4 rounded col">
									{{ $t("affiliate.affiliate_popup.no_data_found") }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import { Datepicker } from "vanillajs-datepicker";
import Datepicker from "vanillajs-datepicker/Datepicker";
import de from "vanillajs-datepicker/locales/de";
import { useUserStore, getAffiliateCustomers } from "@/js/user.js";
import "vanillajs-datepicker/css/datepicker-foundation.css";
import { computed, watch } from "vue";

export default {
	props: {
		affiliatePlans: {
			required: true
		}
	},
	data() {
		return {
			isCodeCopied: false,
			potentialEarnings: [
				{
					affiliateCount: "1",
					additionalBonus: "+0.2TH/s",
					totalBonus: "0.2TH/s"
				},
				{
					affiliateCount: "2",
					additionalBonus: "+0.4TH/s",
					totalBonus: "0.6TH/s"
				},
				{
					affiliateCount: "3",
					additionalBonus: "+0.6TH/s",
					totalBonus: "1.2TH/s"
				},
				{
					affiliateCount: "4",
					additionalBonus: "+0.8TH/s",
					totalBonus: "2.0TH/s"
				},
				{
					affiliateCount: "5",
					additionalBonus: "+1.0TH/s",
					totalBonus: "3.0TH/s"
				},
				{
					affiliateCount: "6+",
					additionalBonus: `+1.0TH/s ${this.$i18n.locale === "en" ? "for each new customers" : "für jeden neuen Kunden"}`,
					totalBonus: `${this.$i18n.locale === "en" ? "from" : "von"} 4.0TH/s`
				}
			],
			affiliateCustomers: [],
			currentBonus: 0,
			friendsRegisteredCount: 1,
			affiliateUrl: "https://block-b.de?affiliate=",
			selectedYearMonth: null,
			datepicker: null,
			customerCode: ""
		};
	},
	methods: {
		copyCode() {
			navigator.clipboard
				.writeText(this.affiliateUrl + this.customerCode)
				.then(() => {
					this.isCodeCopied = true;

					setTimeout(() => {
						this.isCodeCopied = false;
					}, 2000);
				})
				.catch(err => {
					console.error("Failed to copy code to clipboard:", err);
				});
		},
		showCalender() {
			this.$refs.yearMonth.click();
			this.datepicker.show();
		},
		setCalendarTitle(datepicker) {
			// Set calendar title
			const spanElement = document.createElement("span");
			spanElement.textContent = this.selectedYearMonth
				? this.selectedYearMonth
				: this.$t("affiliate.affiliate_popup.everything");
			spanElement.className = "fw-normal float-start me-2";

			const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
			svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
			svgElement.setAttribute("viewBox", "0 0 448 512");

			// Create the path element within the SVG
			const pathElement = document.createElementNS("http://www.w3.org/2000/svg", "path");

			const imgElement = document.createElement("img");
			imgElement.src = require("/src/assets/affiliate/calendar.png");
			imgElement.setAttribute("class", "d-block w-100 calendar-icon");

			pathElement.setAttribute(
				"d",
				"M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"
			);
			svgElement.appendChild(pathElement);

			const closeElement = document.createElement("button");
			closeElement.addEventListener(
				"click",
				function () {
					datepicker.hide();
				},
				false
			);
			closeElement.setAttribute("type", "button");
			closeElement.setAttribute("class", "btn-close btn-close-white");

			document.querySelectorAll(".datepicker-title").forEach(container => {
				container.innerHTML = "";
				container.appendChild(spanElement.cloneNode(true));
				container.appendChild(imgElement.cloneNode(true));
				container.appendChild(closeElement);
			});
		},
		setCalendar() {
			const yearMonthElem = document.querySelector("#yearMonth");

			Object.assign(Datepicker.locales, de);

			this.datepicker = new Datepicker(yearMonthElem, {
				buttonClass: "btn bg-light fw-normal p-0",
				format: "MM yyyy",
				minView: "months",
				pickLevel: 1,
				prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z"/></svg>`,
				nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg>`,
				title: " ",
				clearButton: true,
				language: this.$i18n.locale
			});

			this.setCalendarTitle(this.datepicker);

			// Everything button
			const button = document.querySelector(".datepicker-footer .clear-button");
			button.textContent = this.$t("affiliate.affiliate_popup.everything");

			const footerButton = document.querySelector(".datepicker-footer");
			footerButton.classList.add("mb-3");

			yearMonthElem.addEventListener("changeDate", event => {
				this.selectedYearMonth = event.target.value;
				this.setCalendarTitle();
				this.datepicker.hide();
				this.getAffiliatedCustomers();
			});
		},
		getAffiliatedCustomers() {
			const user = useUserStore();
			this.user = user.$state.user;

			let yearAndMonth = "";

			if (this.selectedYearMonth) {
				const date = new Date(this.selectedYearMonth);
				const year = date.getFullYear();
				const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if needed

				// Format the date as "yyyy-mm"
				yearAndMonth = `${year}-${month}`;
			}

			user.getAffiliateCustomers(yearAndMonth).then(customers => {
				this.affiliateCustomers = customers.affiliate;
				this.friendsRegisteredCount = customers.total_affiliates_activated;
			});
		},
		initialize(user) {
			this.getAffiliatedCustomers();

			user.getAffiliateCurrentBonus().then(bonus => {
				this.currentBonus = bonus.tera_hashes || 0;
			});

			this.customerCode = this.user.selected_customer.customer_code;
		}
	},
	beforeMount() {
		const user = useUserStore();
		
		this.initialize(user);

		const selectedCustomer = computed(() => user.getSelectedCustomer);

		watch(selectedCustomer, async (newVal, oldVal) => {
			this.initialize(user);
		});
	},
	mounted() {
		this.setCalendar();
	}
};
</script>
