export const resetDialogState = () => {
	const forms = document.querySelectorAll("form");

	forms.forEach(form => {
		form.classList.remove("success");
		form.classList.remove("failure");
		form.classList.remove("submitted");
	});
};

export const getIntervalToDisplay = days => {
	const months = days / 30;
	const years = days / 365;

	if (Math.trunc(years) === 1) {
		return "year";
	} else if (Math.trunc(months) === 1) {
		return "month";
	}

	return "day";
};
