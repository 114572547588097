import { useUserStore } from "@/js/user";
import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../pages/LoginPage.vue";
import DashboardPage from "../pages/DashboardPage.vue";
import ProfilePage from "../pages/ProfilePage.vue";
import PasswordResetPage from "../pages/PasswordResetPage.vue";
import { i18n } from "@/main";
import { loginRouteName, resetPasswordRouteName } from "./constants";

export const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			name: loginRouteName,
			path: "/login",
			component: LoginPage,
			meta: { title: "meta_data.login.title" }
		},
		{
			name: "dashboard",
			path: "/",
			component: DashboardPage,
			meta: { title: "meta_data.dashboard.title" }
		},
		{
			name: "profile",
			path: "/profile",
			component: ProfilePage,
			meta: { title: "meta_data.profile.title" }
		},
		{
			name: resetPasswordRouteName,
			path: "/reset-password",
			component: PasswordResetPage,
			meta: { title: "meta_data.reset_password.title" }
		}
	]
});

router.beforeEach(async to => {
	// Remove check on page that does not require user to be authenticated
	if (to.name === resetPasswordRouteName) {
		return true
	}

	const userStore = useUserStore();
	let authenticated = await userStore.isAuthenticated();

	// Avoid reloading of page when already in login
	if (to.name === loginRouteName && !authenticated) {
		return true;
	}

	if (
		// ❗️ Avoid an infinite redirect
		to.name !== loginRouteName &&
		to.name !== resetPasswordRouteName &&
		// make sure the user is authenticated
		!authenticated
	) {
		// redirect the user to the login page
		return "/login";
	}

	if (to.name === loginRouteName && authenticated) {
		return "/";
	}

	document.title = i18n.global.t(to.meta.title) + " | BlockB";

	return true;
});
