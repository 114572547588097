<script setup>
import { RouterView } from "vue-router";
</script>
<template>
	<router-view></router-view>
	<div aria-live="polite" aria-atomic="true" class="position-relative">
		<div id="toasts" class="toast-container position-fixed bottom-0 end-0 p-3">
			<!-- Generated Toasts -->
		</div>
	</div>
</template>

<script>
export default {
	beforeCreate() {
		var currentLanguage = localStorage.getItem("language");
		if (!currentLanguage) currentLanguage = "de";

		this.$i18n.locale = currentLanguage;
	},
	methods: {
		translate(key) {
			return this.$i18n.t(key);
		}
	}
};
</script>
