<style lang="scss" scoped>
#languageSelector {
	position: relative;
	display: inline-block;

	button {
		background: transparent;
		border: 0;
		padding: 0;
		color: white;
		cursor: pointer;
		white-space: nowrap;
	}

	#languageOptions {
		text-align: left;
		position: absolute;
		top: 100%;
		left: -1rem;
		right: -1rem;
		margin-top: 0.25rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-left: 1rem;

		button {
			padding: 0.1rem 0;
		}
	}
}
</style>

<template>
	<div class="d-inline me-4 position-relative multi-select-container" v-if="userStore.$state.user">
		<div class="position-absolute end-0 cursor-pointer">
			<div @click="toggleMultiCustomer()">
				<div class="d-flex mb-1 text-nowrap">
					{{ userStore.$state.user.selected_customer?.customer_name || "" }}
					<font-awesome-icon class="ms-2 mt-1" :icon="['fas', 'angle-down']" />
				</div>
				<small class="mt-1 text-secondary opacity-75 fw-lighter mt-n2 d-block text-start text-sm text-nowrap">{{
					$t("navigation.selected_customer")
				}}</small>
			</div>
			<ul
				v-if="isMultiCustomerListVisible"
				class="list-group bg-dark mt-n2 position-absolute shadow border border-secondary border-opacity-25 p-2"
			>
				<li
					v-for="(customer, index) in userStore.$state.user.customers"
					:key="index"
					@click="selectCustomer(customer.customer_id)"
					:class="`${userStore.$state.user.selected_customer.customer_email === customer.customer_email ? 'active' : ''} text-nowrap list-group-item bg-dark border-0 text-start rounded text-white py-1`"
				>
					{{ customer?.customer_name || "" }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { useUserStore } from "@/js/user";
import { computed, ref, watch } from "vue";
import { toRaw } from "vue";

export default {
	data() {
		return {
			userStore: useUserStore(),
			user: null,
			responsiveSettingsLanguage: ref(false),
			isMultiCustomerListVisible: false,
			selectedCustomer: null
		};
	},
	beforeMount() {
		this.user = this.userStore.$state.user;
	},
	methods: {
		toggleMultiCustomer() {
			this.isMultiCustomerListVisible = !this.isMultiCustomerListVisible;
		},
		async selectCustomer(id) {
			this.isMultiCustomerListVisible = false;
			await this.userStore.getSessionData(id);
		}
	}
};
</script>
