<template>
	<div class="star-icon d-inline">
		<img :class="{ 'opacity-25' : !isFilled}" src="@/assets/feedback/rating-star-icon.svg" />
	</div>
</template>

<script>
export default {
	props: {
		isFilled: {
			type: Boolean
		}
	}
};
</script>
